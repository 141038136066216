import axios from "axios";
import SendMessageToRoom from "./postWebexMessageService";
class AdvisorService {
  static getData(userId) {
    const myPromise = new Promise((resolve, reject) => {
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/users/" + userId + "/advisorTeam";
      let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
      var getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"))
      const getUserAccessToken = getUserToken.accessToken;
      const updatedUserToken = getUserAccessToken.accessToken;
      const Email = getUserAccessToken.claims.sub;
      axios.get(endpoint, {
        headers:
        {
          'token': updatedUserToken,
          'client_id': clientId
        }
      }).then(response => {
        var advisorData = response.data;
        resolve(advisorData);
      })
        .catch(error => { 
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO RETRIEVE ADVISOR INFORMATION

            Domain Name: ${window.location.host} 
            Client Email: ${Email}
            Service: AdvisorService 
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error.response.status : 'Unknown'}
            Error Message: ${error.response.data.message}.`,
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });
    return myPromise;
  }
}

export default AdvisorService;