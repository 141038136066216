import http from "./httpService";
import SendMessageToRoom from "./postWebexMessageService";

class PortfolioRepostsService {
  static getReports() {
    const myPromise = new Promise((resolve, reject) => {
      
      let oktaID = JSON.parse(localStorage.getItem("okta-token-storage"))
      const getOktaID = oktaID.accessToken.claims.uid
      
      
      let endpoint = process.env.REACT_APP_PERFORMANCE_REPORTS + getOktaID;
      // let endpoint = "https://d4c558ec-ca0c-4fc1-85c5-a88ff8638f3a.mock.pstmn.io/first-case"
      // let endpoint = "https://d4c558ec-ca0c-4fc1-85c5-a88ff8638f3a.mock.pstmn.io/second-case"
      // let endpoint = "https://d4c558ec-ca0c-4fc1-85c5-a88ff8638f3a.mock.pstmn.io/third-case"
      // let endpoint = "https://d4c558ec-ca0c-4fc1-85c5-a88ff8638f3a.mock.pstmn.io/fourth-case"

      let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;

      let getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"));
      const getUserAccessToken = getUserToken.accessToken;
      const updatedUserToken = getUserAccessToken.accessToken;
      const Email = getUserAccessToken.claims.sub;

      http
        .get(endpoint, {
          headers: {
            token: updatedUserToken,
            client_id: clientId,
          },
        })
        .then((response) => {
          const respData = response.data;
          resolve(respData);
        })
        .catch((error) => {
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO RETRIEVE PDF REPORT
            
            Domain Name: ${window.location.host} 
            Client Email: ${Email}
            Service: PdfReportsService 
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error.response.status : 'Unknown'}
            Error Message: ${error.response.data.message}.`,
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });
    return myPromise;
  }
}
export default PortfolioRepostsService;
