import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from "@okta/okta-react";
import { useForm } from "react-hook-form";
import BrandService from "../services/brandService";
import help from "../help/well-th-view-user-guide.pdf";
import spinner from "../images/ajax-loader.gif";
import "./login.css";
import Footer from "./footer";

const Login = props => {

  const [pageError, setPageError] = useState("");
  const [loginState, setLoginState] = useState("LOADING");
  const [submitButtonText, setSubmitButtonText] = useState("Login");
  const [factors, setFactors] = useState([]);
  const [selectedFactorType, setSelectedFactorType] = useState("");
  const [recoveryQuestion, setRecoveryQuestion] = useState("");
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();
  const [brandPrefix, setBrandPrefix] = useState("hightower");
  const [domain, setDomain] = useState("hightower");

  const oktaAuth = new OktaAuth({
    authUrl: props.authUrl,
    clientId: props.authClientId,
    redirectUri: props.redirectUri,
    issuer: props.issuer
  });

  useEffect(() => {
    var domain = "";
    if (props.location.search.includes('domain')) {
      var params = props.location.search.split('=');
      domain = params[1];
    } else {
      var host = window.location.host;
      var domains = host.split('.');
      domain = domains[0];
    }
    setDomain(domain);
    const brand = BrandService.getBrandClassPrefix(domain);
    setBrandPrefix(brand);
    props.setBackground(brand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, [])

  useEffect(() => {
    const oktaAuth = props.oktaAuth;

    const parseOktaResponseCOPY = oktaResponse => {
      /*placing a copy of this function inside of useEffect to avoid the dependency issue that I don't fully understand  TF-1/8/20*/
      if (oktaResponse.status === "SUCCESS") {
        sessionStorage.setItem('brandPrefix', domain);
        oktaAuth.redirect({ sessionToken: oktaResponse.sessionToken });
      } else if (oktaResponse.status === "MFA_REQUIRED") {
        oktaResponse.factors.forEach(element => setPrettyFactorMessage(element));
        setFactors(oktaResponse.factors);
        setSubmitButtonText("Send Code");
      } else if (oktaResponse.status === "MFA_CHALLENGE" || oktaResponse.status === "RECOVERY_CHALLENGE") {
        setSubmitButtonText("Verify");
      } else if (oktaResponse.status === "PASSWORD_EXPIRED" || oktaResponse.status === "PASSWORD_RESET") {
        setSubmitButtonText("Save Password");
      } else if (oktaResponse.status === "RECOVERY") {
        setRecoveryQuestion(oktaResponse.user.recovery_question.question);
        setSubmitButtonText("Submit Answer");
      } else {
        //console.log("No matching login result for:", oktaResponse);
      }
      if (oktaResponse.status !== "SUCCESS") {
        //doing this to avoid a flicker on successful login
        setLoginState(oktaResponse.status);
      }
    };

    /* 
    Check if we landed on /login but are already logged in... if so redirect so proper session is established 
    - this will happen if someone comes from the okta embed link
    */

    if (oktaAuth.session) {
      oktaAuth.session
        .get()
        .then(session => {
          if (session.status === "ACTIVE") {
            oktaAuth.token
              .getWithoutPrompt({
                responseType: "id_token"
              })
              .then(tokenOrTokens => {
                oktaAuth.tokenManager.setTokens(tokenOrTokens.tokens);
                history.push("/welcome")
              })
              .catch(err => {
                //console.log('catch err');
                setLoginState("");
              });
          } else {
            //console.log('no status active');
            setLoginState("");
          }
        })
        .catch(err => {
          //console.log('catch err 2');
          setLoginState("");
        });
    }

    /* 
    Check if we landed on /login with a transaction in progress... if so pick up where we left off 
    - this will happen if someone refreshes the page during login
    */
    if (oktaAuth.tx.exists()) {
      oktaAuth.tx
        .resume()
        .then(function (oktaTrans) {
          if (history.action === "PUSH") {
            oktaTrans.cancel().then();
          } else {
            parseOktaResponseCOPY(oktaTrans);
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    } else {
      //console.log("no transaction");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oktaAuth, props.authUrl, props.authClientId, props.redirectUri, props.issuer]);

  const onSubmit = async data => {
    try {
      let oktaResponse = {};
      setPageError("");

      // call okta
      if (!oktaAuth.tx.exists()) {
        if (loginState === "") {
          oktaResponse = await oktaAuth.signInWithCredentials({
            username: data.username,
            password: data.password
          });
        } else if (loginState === "FORGOT_PASSWORD") {
          oktaResponse = await oktaAuth.forgotPassword({
            username: data.forgotPasswordUsername,
            factorType: "SMS"
          })
        } else {
          setLoginState("");
        }
      } else {
        const trans = await oktaAuth.tx.resume();
        //console.log("transaction is", trans);
        if (trans.status === "MFA_REQUIRED") {
          if (trans.factors) {
            const factor = trans.factors.find(item => item.factorType === selectedFactorType);
            if (factor) {
              oktaResponse = await factor.verify();
            } else {
              setPageError("Please select a verification method");
              return;
            }
          }
        } else if (trans.status === "MFA_CHALLENGE" || trans.status === "RECOVERY_CHALLENGE") {
          oktaResponse = await trans.verify({ passCode: data.verificationCode, rememberDevice: true });
        } else if (trans.status === "RECOVERY") {
          oktaResponse = await trans.answer({ answer: data.recoveryAnswer });
        } else if (trans.status === "PASSWORD_EXPIRED") {
          if (data.newPassword === data.reenterPassword) {
            oktaResponse = await trans.changePassword({
              oldPassword: data.oldPassword,
              newPassword: data.newPassword
            });
          } else {
            setPageError("New password and reentered password must match");
            return;
          }
        } else if (trans.status === "PASSWORD_RESET") {
          if (data.newPassword === data.reenterPassword) {
            oktaResponse = await trans.resetPassword({
              newPassword: data.newPassword
            });
          } else {
            setPageError("New password and reentered password must match");
            return;
          }
        } else {
          setLoginState("");
        }
      }
      //console.log("result is", oktaResponse);
      parseOktaResponse(oktaResponse);
    } catch (error) {
      setPageError(parseOktaError(error));
    }
  }

  const onForgotPassword = () => {
    setLoginState("FORGOT_PASSWORD");
    setPageError("");
    setSubmitButtonText("Request New Password");
  }

  const onOptionChange = changeEvent => {
    setSelectedFactorType(changeEvent.target.value);
  }

  const parseOktaResponse = oktaResponse => {
    if (!oktaResponse || !oktaResponse.status) {
      console.error("Invalid Okta response received.");
      window.location.reload(); //timeout or we got something wacky from okta so start over
    }
    if (oktaResponse.status === "SUCCESS") {
      // oktaAuth.session.setCookieAndRedirect(oktaResponse.sessionToken, window.location.origin +'/menu');
      sessionStorage.setItem('brandPrefix', domain);
      oktaAuth.token.getWithRedirect({
        sessionToken: oktaResponse.sessionToken,
        responseType: 'id_token'
      });

    } else if (oktaResponse.status === "MFA_REQUIRED") {
      oktaResponse.factors.forEach(element => setPrettyFactorMessage(element));
      setFactors(oktaResponse.factors);
      setSubmitButtonText("Send Code");
    } else if (oktaResponse.status === "MFA_CHALLENGE" || oktaResponse.status === "RECOVERY_CHALLENGE") {
      setSubmitButtonText("Verify");
    } else if (oktaResponse.status === "PASSWORD_EXPIRED" || oktaResponse.status === "PASSWORD_RESET") {
      setSubmitButtonText("Save Password");
    } else if (oktaResponse.status === "RECOVERY") {
      setRecoveryQuestion(oktaResponse.user.recovery_question.question);
      setSubmitButtonText("Submit Answer");
    } else {
      console.error("No matching login result for:", oktaResponse);
    }
    if (oktaResponse.status !== "SUCCESS") {
      //doing this to avoid a flicker on successful login
      setLoginState(oktaResponse.status);
    }
  }

  const parseOktaError = error => {
    if (error && error.errorCode === "E0000004") return "Invalid username or password";
    if (error && error.errorCode === "E0000068") return "Invalid verification code";
    if (error && error.errorCauses && error.errorCauses.length > 0) return error.errorCauses[0].errorSummary;
    else if (error && error.message) return error.message;
    else return "An unknown error occurred";
  }

  const setPrettyFactorMessage = factor => {
    factor.prettyMessage = factor.factorType + " " + factor.profile.phoneNumber;
    if (factor.factorType === "sms") factor.prettyMessage = "SMS message to " + factor.profile.phoneNumber;
    if (factor.factorType === "call") factor.prettyMessage = "Phone call to " + factor.profile.phoneNumber;
  }

  return (
    <>
      <div className="container pt-5 main-container">
        <div className="row">
          <div className="col-lg-6 col-md-9 mx-auto drop-shadow p-5 login-form">
            <div className="text-center pb-2">
              <img className="img-fluid logo" src={require(`../images/${brandPrefix}-logo.png`).default} alt={brandPrefix} />
            </div>
            <hr className="pb-2" />
            <h3 className="text-center pb-2">Client Sign In</h3>
            {pageError && (
              <div className="alert alert-danger" role="alert">
                {pageError}
              </div>
            )}
            <form className="p-1" onSubmit={handleSubmit(onSubmit)}>
              {/* 
            ID PWD Fields 
            */}
              {loginState === "" && (
                <div className="id-pwd-form">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Username" name="username" {...register('username', { required: true, pattern: /^\S+@\S+$/i })} autoComplete="off" />
                    {errors.username && <div className="alert alert-danger">A valid email address is required</div>}
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" placeholder="Password" name="password" {...register('password', { required: true })} autoComplete="off" />
                    {errors.password && <div className="alert alert-danger">Password is required</div>}
                  </div>
                  <div className="form-group">
                    <button type="button" className="btn btn-link" onClick={onForgotPassword}>
                      Forgot Password?
                    </button>
                    <a href={help} target="_blank" rel="noreferrer"> Help </a>                   
                  </div>
                </div>
              )}
              {/* 
            MFA Choices 
            */}
              {loginState === "MFA_REQUIRED" && (
                <div className="id-mfa-form">
                  <p>How would you like to receive your verification code?</p>
                  <div className="form-check pb-3">
                    {factors.map(item => (
                      <div className="radio" key={item.id}>
                        <label>
                          <input
                            className="form-check-input"
                            type="radio"
                            name={item.id}
                            id={item.id}
                            value={item.factorType}
                            checked={item.factorType === selectedFactorType ? "checked" : ""}
                            onChange={onOptionChange}
                          />
                          &nbsp;&nbsp;{item.prettyMessage}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* 
            MFA Enter Code 
            */}
              {(loginState === "MFA_CHALLENGE" || loginState === "RECOVERY_CHALLENGE") && (
                <div className="id-mfa-form">
                  <p>Please enter your verification code</p>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Verification Code" name="verificationCode" {...register('verificationCode', { required: true })} autoComplete="off" />
                    {errors.verificationCode && <div className="alert alert-danger">Verification Code is required</div>}
                  </div>
                </div>
              )}
              {/* 
            Change Password Field 
            */}
              {(loginState === "PASSWORD_EXPIRED" || loginState === "PASSWORD_RESET") && (
                <div className="id-pwd-form">
                  <p>Please enter your new password</p>
                  {loginState === "PASSWORD_EXPIRED" && (
                    <div className="form-group">
                      <input type="password" className="form-control" placeholder="Old Password" name="oldPassword" {...register('oldPassword', { required: true })} autoComplete="off" />
                      {errors.oldPassword && <div className="alert alert-danger">Old password is required</div>}
                    </div>
                  )}
                  <div className="form-group">
                    <input type="password" className="form-control" placeholder="New Password" name="newPassword" {...register('newPassword', { required: true })} autoComplete="off" />
                    {errors.newPassword && <div className="alert alert-danger">New password is required</div>}
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" placeholder="Reenter New Password" name="reenterPassword" {...register('reenterPassword', { required: true })} autoComplete="off" />
                    {errors.reenterPassword && <div className="alert alert-danger">Reenter you new password</div>}
                  </div>
                </div>
              )}
              {/* 
            Forgot Password Fields 
            */}
              {loginState === "FORGOT_PASSWORD" && (
                <div className="id-pwd-form">
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Username" name="forgotPasswordUsername" {...register('forgotPasswordUsername', { required: true, pattern: /^\S+@\S+$/i })} autoComplete="off" />
                    {errors.forgotPasswordUsername && <div className="alert alert-danger">A valid email address is required</div>}
                  </div>
                </div>
              )}
              {/* 
            Password Recovery Fields (secret questions)
            */}
              {loginState === "RECOVERY" && (
                <div className="id-pwd-form">
                  <div className="form-group">
                    <p>{recoveryQuestion}</p>
                    <input type="text" className="form-control" placeholder="Answer" name="recoveryAnswer" {...register('recoveryAnswer', { required: true })} autoComplete="off" />
                    {errors.recoveryAnswer && <div className="alert alert-danger">A answer is required</div>}
                  </div>
                </div>
              )}
              {/* 
            Submit Button
            */}
              {loginState !== "LOADING" && loginState !== "LOCKED_OUT" && (
                <div className="form-group">
                  <button type="submit" className={`${brandPrefix}-button`}>
                    {submitButtonText}
                  </button>
                </div>
              )}
              {loginState === "LOADING" && (
                <div className="center-text">
                  <img className="mx-auto d-block" src={spinner} alt="Waiting" />
                </div>
              )}
              {loginState === "LOCKED_OUT" && (
                <div className="center-text">
                  <div className="alert alert-danger" role="alert">
                    You have been locked out of your account.   Please contact your advisor for assistance.
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>

      </div>
      <div className="login-footer-container">
        <Footer />
      </div>
    </>
  )
}

export default withOktaAuth(Login)
