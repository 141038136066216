class BrandService {
  static getBrandClassPrefix(host) {
    let prefixes = {
      "synergycapitalsolutions": "synergy",
      "falconwealthadvisors": "falcon",
      "alinewealth": "aline",
      "treasurypartners": "tp",
      "morgiawm": "morgia",
      "harveyinvestmentmanagement": "harvey",
      "thebahnsengroup": "tbg",
      "fortresswealthplanning": "fortress",
      "cogneticcapital": "cognetic",
      "hummermower": "hma",
      "somersetadvisory": "somerset",
      "lexingtonwealth": "lexington",
      "irongatepartnersinc": "irongate",
      "dsgadvisors": "dsg",
      "abc": "abc",
      "schultzcollins": "schultz",
      "stearnsfinancial": "stearns",
      "lourdmurray": "lourd",
      "investsg":"isg",
      "argent":"argent",
      "teaktreecapital":"teaktree",
      "bluerockwealth":"bluerock",
      "theandriolegroup":"andriolegroup",
      "greensquareco":"greensquare",
      "rikoongroup":"rikoon",
      "clearperspectiveadvisors":"clearperspective",
      "fairportwealth":"fairport",
      "alexandriacapital":"alexcapital",
      "riagroups":"ria",
      "twickenhamadvisors":"twickenham",
      "rdmfinancial":"rdm",
      "hightowergreatlakes":"great-lakes",
      "hightowerbethesda":"bethesda",
      "landsbergbennett": "landsbergbennett",
      "httcwealthpartners": "httcwealthpartners",
      "6meridian": "sixmeridian",
      "natomawealth":"natoma",
      "hightowerlasvegas":"lasvegas",
      "thedilligbowengroup":"thedilligbowen",
      "sillerandcohen":"sillerandcohen",
      "vwg":"vwg",
      "lck":"lck",
      "213strategicpartners":"strategicpartners",
      "mtwhadvisors":"mtwh",
      "trg": "rand",
      "claritypoint":"claritypoint",
      "thebahnsengrouptest":"tbgtest",
      "bickling":"bickling",
      "hightoweraustin":"austin",
      "presidiowp":"presidio",
      "feinbergstein":"feinberg",
      "highlandprivate":"highland",
      "strata":"strata",
      "xceptionadvisorygroup":"xception",
    };
    return prefixes.hasOwnProperty(host) ? prefixes[host] : "hightower";
  }
}

export default BrandService;
