import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { CgCalendarNext } from "react-icons/cg";
import"./Quicklink.css"
function Quicklink({ quickLinks }) {
    const chunkArray = (array, chunkSize) => {
        return Array.from({ length: Math.ceil(array?.length / chunkSize) }, (_, index) => {
            const start = index * chunkSize;
            return array.slice(start, start + chunkSize);
        });
    };
    const linksGroups = chunkArray(quickLinks, 2);
    return (
        <Carousel data-bs-theme="black" className='m-Carousel' controls={false} >
            {linksGroups.map((row, index) => (
                <Carousel.Item key={index}>
                    <div >
                        {row.map((link, linkIndex) => (
                            <div key={linkIndex}>

                                <div className='q-content' style={{ display: 'flex', alignItems: 'center' }}>
                                    {(link.LogoFile?.split("/"))[(link.LogoFile?.split("/"))?.length - 1].split(".")[1] === "svg" ? (
                                        <img className='img-quicklink' src={require(`../images/vendor-logos/${(link.LogoFile?.split("/"))[(link.LogoFile?.split("/")).length - 1]}`).default} alt={`${link.Title} icon`} />
                                    ) : (
                                        <img className='img-quicklink' src={require(`../images/vendor-logos/${(link.LogoFile?.split("/"))[(link.LogoFile?.split("/")).length - 1]}`).default} alt={`${link.Title} icon`} />
                                    )}
                                    <div>
                                        <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '-2px', color: '#6c757d!important' }}> {link?.Title}</h3>
                                        <a
                                            href={link.URL}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{
                                                textDecoration: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontStyle: 'italic',
                                                color: '#6c757d!important;',
                                                fontSize: '14px'
                                            }}
                                        >
                                             {link.Title} Portal <CgCalendarNext style={{ marginLeft: '6px' }} />
                                        </a>
                                    </div>
                                </div>
                                {linkIndex === 0 && row.length === 2 && (
                                    <div style={{ color: "black", width: "100%", height: "1px", margin: "10px 10px", backgroundColor: "#d8d6d6" }}></div>
                                )}
                            </div>
                        ))}

                    </div>
                </Carousel.Item>

            ))

            }
        </Carousel>
    )
}

export default Quicklink