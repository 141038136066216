import { useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom";
import BrandService from "../../services/advisorService";
import UserService from "../../services/userService";
// import AdvisorService from "../../services/advisorService";
// import ReactGA from "react-ga";
import { withOktaAuth } from "@okta/okta-react";
import PortfolioRepostsService from "../../services/PortfolioRepostsService";
// import PortfolioRepostsService2 from "../../services/PortfolioRepostsService2";
import PdfReportsService from "../../services/PdfReportsService";
import Form from "react-bootstrap/Form";
import Header from "../header";
import { tableHeadings } from "../../lib/constants";
import vaultImages from "../../images/vendor-logos/vault.svg";
import { MdOutlineFolderOpen } from "react-icons/md";
import { BsBoxArrowUpRight, BsFileEarmarkFill, BsFillEyeFill} from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import Footer from "../footer";
import Button from "react-bootstrap/Button";

import Table from "react-bootstrap/Table";
import "./AccordionComponent.css";
import spinner from "../../images/ajax-loader.gif";
import PdfPreview from "./pdfPreview";
import CustomBackToggle from "./CustomBackToggle";
import Spinner from "react-bootstrap/Spinner";
import PaginatedItems from "./Pagination";
import Pershing from "../VaultComponents/pershingComponent/Pershing";

const AccordionComponent = (props) => {
  const [noPershing, setNoPershing] = useState(false);
  const [noBd, setNoBd] = useState(false);
  //console.log('checkPershingDocs', checkPershing, checkBd);
  const { userName } = props?.location?.state || {};
  const [userInfo, setUserInfo] = useState();
  const [userId, setUserId] = useState();
  const [sessionId, setSessionId] = useState();
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState();
  const [brandPrefix, setBrandPrefix] = useState("hightower");
  // const [advisor, setAdvisor] = useState();
  const [performance, setPerformance] = useState(false);
  const [showFilter, setShowFilter] = useState();
  const [bdUserId, setBdUserId] = useState();

  const [reportsResponseData, setReportsResponseData] = useState([]);
  const [reports, setReports] = useState([]);
  const [uniqueYears, setUniqueYears] = useState([]);
  const history = useHistory();
  const [showPDF, setShowPDF] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfFileName, setPdfFileName] = useState("");
  const [filterData, setFilterData] = useState({
    year: "all",
    quarter: "",
  });
  /*-------------- Pagination Code begin here **************************/
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  let backBtnRef = useRef();
  const [currentPosts, setCurrentPosts] = useState([]);
  // when user clicks on number this function will execute
  const paginate = (number) => {
    setCurrentPage(number);
  };
  /*-------------- Pagination Code end here **************************/
  useEffect(() => {
    var domain = "";
    if (props.location.search.includes("domain")) {
      var params = props.location.search.split("=");
      domain = params[1];
      const brand = BrandService.getBrandClassPrefix(domain);
      setBrandPrefix(brand);
      props.setBackground(brand);
      sessionStorage.setItem("brandPrefix", brand);
    } else {
      var brandDomain = sessionStorage.getItem("brandPrefix");
      if (brandDomain !== null && brandDomain !== undefined) {
        setBrandPrefix(brandPrefix);
      } else {
        var host = window.location.host;
        var domains = host.split(".");
        domain = domains[0];
        const brand = BrandService.getBrandClassPrefix(domain);
        setBrandPrefix(brand);
        props.setBackground(brand);
        sessionStorage.setItem("brandPrefix", domain);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    props.oktaAuth.getUser().then((user) => {
      setUserId(user.sub);
      props.oktaAuth.session.get().then((session) => {
        if (session.status === "INACTIVE") {
          history.push("/login");
        }
        setSessionId(session.id);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oktaAuth]);

  useEffect(() => {
    if(sessionId !== undefined && userId !== undefined){
    UserService.getData(userId, sessionId)
        .then((result) => {
          setUserInfo(result);
          // const bdportalID = result.BDPortalUserId;
          // const bdID = result.Id;
          setBdUserId(result.Id);
        }).catch((e)=>{
          console.log(e)
          history.push('/error')
        })
      }
    const reportsData = JSON.parse(localStorage.getItem('List-of-Reports'))
    if(reportsData){
      setReports(reportsData);
              setReportsResponseData(reportsData);
              console.log('resData.listOfReports', reportsData);
              let liveData = reportsData
              if (reportsData.length === 0) {
                setNoBd(true);
              }
              liveData.sort((a, b) => {
                return new Date(b.endDate) - new Date(a.endDate);
              })
              setReports(liveData);
              setLoading(true);
    }
    else {
      UserService.getData(userId, sessionId)
        .then((result) => {
          setUserInfo(result);
          const bdportalID = result.BDPortalUserId;
          const bdID = result.Id;
          // setBdUserId(bdID);
          // setUserName(result.Name);

          PortfolioRepostsService.getReports(bdportalID, bdID)
            .then((resData) => {
              if (resData.listOfReports.length === 0) {
                setNoBd(true);
              }
              setReports(resData.listOfReports);
              setReportsResponseData(resData.listOfReports);
              console.log('resData.listOfReports', resData.listOfReports);
              let liveData = resData.listOfReports
              liveData.sort((a, b) => {
                return new Date(b.endDate) - new Date(a.endDate);
              })
              setReports(liveData);
              setLoading(true);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          history.push("/error");
        });
    }
  }, [userId, sessionId, history]);


  useEffect(() => {
    if (!!showFilter) {
      const getQuarter = (value) => {
        let date = new Date(value);
        const quaterValue = Math.floor(date.getMonth() / 3 + 1);
        if (quaterValue === 1) return "q1";
        else if (quaterValue === 2) return "q2";
        else if (quaterValue === 3) return "q3";
        else if (quaterValue === 4) return "q4";
      };

      const getTotalYears = (value) => {
        let date = new Date(value);
        return date.getFullYear();
      };

      const filteredData = reportsResponseData.filter(
        (item) =>
          getQuarter(item.endDate) === (!!filterData?.quarter ? filterData?.quarter : "q1") &&
          getTotalYears(item.endDate).toString() === (!!filterData?.year ? filterData?.year : uniqueYears[0].toString())
      );

      setReports(filteredData);
    }
  }, [reportsResponseData, filterData, showFilter, uniqueYears]);

  useEffect(() => {
    if (!showFilter) {
      setReports(reportsResponseData);
    }
  }, [reportsResponseData, showFilter]);

  const handleHome = () => {
    history.push("menu");
  };
  const handlePerformance = () => {
    setPerformance(!performance);
  };

  const handleTable = (value) => {
    if (value === "all") {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };
  const handleBackBtn = () => {
    backBtnRef.current.click();
  };

  const handleDownloadPDF = (Id, name, index) => {
    setLoadingIndex(index);
    setDownloadLoading(true);
    bdUserId && PdfReportsService.getPDFFile(bdUserId, Id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        setDownloadLoading(false);
        link.setAttribute("download", `${name}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePreviewPDF = (Id, name, index) => {
    setLoadingIndex(index);
    setPreviewLoading(true);
    bdUserId &&  PdfReportsService.getPDFFile(bdUserId, Id).then((response) => {
      console.log("BD pdf res: ", response);
      setPdfUrl(response);
      setPdfFileName(name);
      setPreviewLoading(false);
      setShowPDF(true);
    }).catch((e) => {
console.log(e)
    });
  };

  /* Date converted to MM-DD-YYYY format */
  const formatDate = (value) => {
    let month = value.substring(5, 7);
    let date = value.substring(8, 10);
    let year = value.substring(0, 4);
    let mmddyy = month + "-" + date + "-" + year;
    return mmddyy;
  };

  useEffect(() => {
    const uniqeYrs = reportsResponseData.map((item) => {
      let date = new Date(item.endDate);
      return date.getFullYear();
    });

    setUniqueYears([...new Set(uniqeYrs)]);
  }, [reportsResponseData]);
  if (noBd && noPershing) {
    window.open(props.location.state.boxURL, '_blank');
    history.push('/menu'); 
  }
  return (
    <>
    <div className="main-container" style={{ height: "100%" }}>
      <Header brandPrefix={brandPrefix} username={userName} />
      <div>
      {!showPDF && (
        <div className="container">
          <div className="vault-img">
            <img src={vaultImages} width="20%" alt="vault" />
            <div className="home-btn">
              {!performance ? (
                <Button onClick={handleHome} className="btn-home" id="button">
                  Home
                </Button>
              ) : (
                <Button onClick={handleBackBtn} className="btn-home" id="button">
                  Back
                </Button>
              )}
            </div>
          </div>
          {!!loading && (
            <Accordion defaultActiveKey={!!performance ? "1" : ""}>
              <a href={props.location.state.boxURL} target="_blank" className="accordion" rel="noreferrer">
                <div className="card-horizontal">
                  <div className="accordion-heading">
                    <MdOutlineFolderOpen className="icon" />
                    {/* {userName} Household */}
                    Your Shared Files
                    <BsBoxArrowUpRight className="duty-icon" />
                  </div>
                </div>
              </a>
              {reportsResponseData && reportsResponseData.length > 0 && <Accordion.Item eventKey="1">
                <Accordion.Header onClick={handlePerformance}>
                  <CustomBackToggle backBtnRef={backBtnRef} eventKey="1" handleCollapse={handlePerformance} />

                  <span className="accordion-heading">
                    <MdOutlineFolderOpen className="icon" />
                    Portfolio Reports and Statements ({reportsResponseData && reportsResponseData.length})
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="row">
                      <div className="col-md-8"></div>
                      <div className="col-md-4">
                        {/* <div className="filter-heading" onClick={handleTable}>
                          <BsFilterLeft className="filter-icon" />
                          <h5>Filters</h5>
                        </div> */}

                        {/* {showFilter && ( */}
                        <div className="filter-container">
                          <div className="mr-3 d-flex">
                            Year
                            <Form.Select
                              aria-label="Default select"
                              onChange={(e) => {
                                setFilterData({
                                  ...filterData,
                                  year: e?.target?.value,
                                });
                                handleTable(e?.target?.value);
                              }}
                            >
                              <option value="all">All</option>
                              {uniqueYears &&
                                uniqueYears.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                            </Form.Select>
                          </div>
                          <div className="d-flex">
                            Quarter
                            <Form.Select
                              aria-label="Default select "
                              onChange={(e) => {
                                setFilterData({
                                  ...filterData,
                                  quarter: e?.target?.value,
                                });
                                handleTable(e?.target?.value);
                              }}
                            >
                              {filterData?.year === "all" ? (
                                <option value="all">All</option>
                              ) : (
                                <>
                                  <option value="q1">Q1</option>
                                  <option value="q2">Q2</option>
                                  <option value="q3">Q3</option>
                                  <option value="q4">Q4</option>
                                </>
                              )}
                            </Form.Select>
                          </div>
                        </div>
                        {/* )} */}
                      </div>
                    </div>

                    <div className="table">
                      <Table striped>
                        <thead>
                          <tr>
                            <th>{tableHeadings.name}</th>
                            <th>{tableHeadings.portfolio}</th>
                            <th width="120">{tableHeadings.startdate}</th>
                            <th width="120">{tableHeadings.enddate}</th>
                            <th width="120">{tableHeadings.pageCount}</th>
                            <th width="120">{tableHeadings.dateposted}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPosts &&
                            currentPosts.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <BsFileEarmarkFill className="grid-icons mr-1" />
                                  {item.name}
                                </td>
                                <td>{item.levelName}</td>
                                <td>{formatDate(item.startDate)}</td>
                                <td>{formatDate(item.endDate)}</td>
                                <td>{item.pageCount}</td>
                                <td>{formatDate(item.createdDate)}</td>
                                <td>
                                  {(!previewLoading || loadingIndex !== index) && <BsFillEyeFill className="grid-icons pdfViwer" onClick={() => handlePreviewPDF(item.id, item.name, index)} />}
                                  {!!previewLoading && loadingIndex === index && <Spinner animation="border" size="sm" />}
                                </td>

                                <td>
                                  {(!downloadLoading || loadingIndex !== index) && <HiDownload onClick={() => handleDownloadPDF(item.id, item.name, index)} className="grid-icons pdfViwer" />}
                                  {!!downloadLoading && loadingIndex === index && <Spinner animation="border" size="sm" />}
                                </td>
                              </tr>
                            ))}
                          {/* {currentPosts.length < 0 && <div>No Reports Found</div>} */}
                        </tbody>
                      </Table>

                      <div className="pagination">
                        <PaginatedItems paginate={paginate} reports={reports} itemsPerPage={postsPerPage} setCurrentPosts={setCurrentPosts} showFilter={showFilter} currentPage={currentPage} />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              }
              <Pershing setNoPershing={setNoPershing} boxURL={props.location.state.boxURL}/>
            </Accordion>
          )}
          
          {!loading && (
            <div className="spinner-background">
              <div className="container spinner-background">
                <div className="mx-auto d-block justify-content-center text-center mt-4">
                  <h2>We're loading your reports...</h2>
                </div>
                <div className="mx-auto d-block justify-content-center text-center mt-3">
                  <img src={spinner} alt="spinner" />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showPDF && <PdfPreview setShowPDF={setShowPDF} pdfUrl={pdfUrl} pdfFileName={pdfFileName} />}
      </div>
    
    </div>
    <div className="accordion-footer-container">
      <Footer userInfo={userInfo} />
  </div>
    </>
  );
};
export default withOktaAuth(AccordionComponent);
