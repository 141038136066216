import React, { useState, useEffect } from "react";
import UserService from "../services/userService";
import AdvisorService from "../services/advisorService";
import BrandService from "../services/brandService";
import { withOktaAuth } from "@okta/okta-react";
import Advisors from "./advisorsComponent/advisors";
import Portals from "./portals";
import Header from "./header";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import LegalTermsconditions from "../components/LegalTermsconditions";
import UserInfo from "./userInfo";
import LogRocket from "logrocket";
import "./menu.css";
import spinner from "../images/ajax-loader.gif";
import InvocePayLink from "./InvoicePayLink";
import Footer from "./footer";

const Menu = (props) => {
  const [userId, setUserId] = useState();
  const [sessionId, setSessionId] = useState();
  const [userEmail, setUserEmail] = useState();
  const [portalLoading, setPortalLoading] = useState(true);
  const [headerLoading, setHeaderLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [userName, setUserName] = useState();
  const [isConsentAccepted, setIsConsentAccepted] = useState(true);
  const [brandPrefix, setBrandPrefix] = useState("hightower");
  const [advisor, setAdvisor] = useState();
  const [advisorError, setAdvisorError] = useState(false);
  const [reportsLoad, setReportsLoad] = useState(true);
  const history = useHistory();

  useEffect(() => {
    var domain = "";
    if (props.location.search.includes("domain")) {
      var params = props.location.search.split("=");
      domain = params[1];
    } else {
      var brandDomain = sessionStorage.getItem("brandPrefix");
      if (brandDomain !== null && brandDomain !== undefined) {
        domain = brandDomain;
      } else {
        var host = window.location.host;
        var domains = host.split(".");
        domain = domains[0];
      }
    }

    const brand = BrandService.getBrandClassPrefix(domain);
    setBrandPrefix(brand);
    if (props.onBrandChange) {
      props.onBrandChange(brand);
    }
    sessionStorage.setItem("brandPrefix", domain);
  }, [props, props.location.search, props.onBrandChange]);

  useEffect(() => {
    props.oktaAuth.getUser().then((user) => {
      setUserId(user.sub);
      setUserEmail(user.email);

      props.oktaAuth.session.get().then((session) => {
        if (session.status === "INACTIVE") {
          history.push("/login");
        }
        setSessionId(session.id);
      });
    });
  }, [history, props.oktaAuth]);

  useEffect(() => {
    if (sessionId !== undefined && userId !== undefined) {
      const user = JSON.parse(localStorage.getItem("Users"));
      // console.log("user local: ", user);
      if (user !== null && user !== undefined) {
        setUserInfo(user);
        setUserName(user.Name);
        setIsConsentAccepted(user?.isConsentAgreed);
        LogRocket.identify(userId, {
          name: user.Name,
          email: userEmail,
        });
      } else {
        UserService.getData(userId, sessionId)
          .then((result) => {
            if (result) {
              setUserInfo(result);
              setUserName(result?.Name);
              setIsConsentAccepted(result?.isConsentAgreed);
              LogRocket.identify(userId, {
                name: result?.Name,
                email: userEmail,
              });
            }
          })
          .catch((error) => {
            if (error) {
              history.push("/error");
            }
          });
      }
      setHeaderLoading(false);
    }
  }, [userId, sessionId, userEmail, history]);

  useEffect(() => {
    var advisor = JSON.parse(localStorage.getItem("advisor"));
    if (advisor !== null && advisor !== undefined) {
      setAdvisor(advisor);
    } else {
      if (sessionId !== undefined && userId !== undefined) {
        AdvisorService.getData(userId, sessionId)
          .then((result) => {
            if (result) {
              localStorage.setItem("advisor", JSON.stringify(result));
              setAdvisor(result);
            }
          })
          .catch((error) => {
            localStorage.setItem("advisorError", true);
            setAdvisorError(true)
            console.log(error);
          });
      }
    }
  }, [userId, sessionId]);

  useEffect(() => {
    if (advisor) {
      ReactGA.set("Advisor Practice", advisor.AnalyticsTag);
    }
  }, [advisor]);

  useEffect(() => {
    if (!portalLoading && !headerLoading) {
      setLoading(false);
    }
  }, [portalLoading, headerLoading]);
  return (
    <>
      {userId && sessionId && (
        <div className="main-container">
          {loading && (
            <div className="spinner-background">
              <div className="container spinner-background">
                <div className="mx-auto d-block justify-content-center text-center mt-3">
                  <img src={spinner} alt="spinner" />
                </div>
              </div>
            </div>
          )}
          {!loading && <Header brandPrefix={brandPrefix} username={userName} />}
          {!isConsentAccepted &&
            <LegalTermsconditions
              isConsentAccepted={isConsentAccepted}
              setIsConsentAccepted={setIsConsentAccepted}
              userId={userId}
              sessionId={sessionId}
              //setUserAgreement={setUserAgreement}
              setLoading={setLoading}
            />
          }
          <>
            {!loading && <InvocePayLink userInfo={userInfo} />}
            {!loading && advisor && <Advisors advisorTeam={advisor} />}
            {!loading && advisorError && <div style={{ height: "30px" }}></div>}

            <Portals
              applications={(loading) ? 'inititializing' : ((reportsLoad) ? 'loading' : 'ready')}
              userInfo={userInfo}
              userId={userId}
              sessionId={sessionId}
              reportsLoad={reportsLoad}
              renderComplete={() => setPortalLoading(false)}
              reportsComplete={() => setReportsLoad(false)}
            />
            {!loading && userInfo && <UserInfo userInfo={userInfo} />}
          </>
        </div>
      )}
      <div className="menu-footer-container">{!loading && <Footer userInfo={userInfo} />}</div>
    </>
  );
};

export default withOktaAuth(Menu);