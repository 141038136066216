import { useState, useEffect } from "react";

import AdvisorService from "../services/advisorService";
import { withOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import spinner from "../images/ajax-loader.gif";

const Welcome = (props) => {
  const [userId, setUserId] = useState();
  const [sessionId, setSessionId] = useState();
  const history = useHistory();
  const oldomain = "hightoweradvisors";
  const defaultDomain = "well-thview";

  useEffect(() => {
    props.oktaAuth.getUser().then((user) => {
      setUserId(user.sub);
      props.oktaAuth.session.get().then((session) => {
        if (session.status === "INACTIVE") {
          history.push("/login");
        } else {
          props.oktaAuth.token
            .getWithoutPrompt({
              responseType: "id_token",
            })
            .then((tokenOrTokens) => {
              props.oktaAuth.tokenManager.setTokens(tokenOrTokens.tokens);
              setSessionId(session.id);
            })
            .catch((err) => {
              console.log("error " + err);
            });
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oktaAuth]);

  function doRedirection(url) {
    if (window.location.origin + "/menu" === url) {
      history.push("/menu");
    } else {
      window.location.href = url;
    }
  }

  function redirect(clientExperience, brandPrefix, domainBrand) {
    if (clientExperience.toLowerCase() === process.env.REACT_APP_CLIENT_EXPERIENCE_LATEST.toLowerCase()) {
      if (brandPrefix !== null && brandPrefix !== undefined) {
        if (brandPrefix !== defaultDomain) {
          doRedirection(process.env.REACT_APP_NEW_PORTAL_URL_BRANDING.replace("hightoweradvisors", brandPrefix));
        } else {
          doRedirection(process.env.REACT_APP_NEW_PORTAL_URL);
        }
        return;
      }
      if (domainBrand) {
        if (domainBrand !== "" && domainBrand !== defaultDomain && domainBrand !== oldomain) {
          doRedirection(process.env.REACT_APP_NEW_PORTAL_URL_BRANDING.replace("hightoweradvisors", domainBrand));
        } else {
          doRedirection(process.env.REACT_APP_NEW_PORTAL_URL);
        }
        return;
      } else {
        window.location.href = process.env.REACT_APP_NEW_PORTAL_URL;
        return;
      }
    } else {
      //old site
      window.location.href = process.env.REACT_APP_OLD_PORTAL_URL;
      return;
    }
  }

  useEffect(() => {
    if (sessionId !== undefined && userId !== undefined) {
      AdvisorService.getData(userId, sessionId)
        .then((result) => {
          if (result) {
            sessionStorage.setItem("advisor", JSON.stringify(result));
            var brandPrefix = sessionStorage.getItem("brandPrefix");
            var domains;
            if (result.Domain) {
              domains = result.Domain.split(".");
            }
            redirect(result.ClientPortalExperience, brandPrefix, domains[0]);
          } else {
            window.location.href = process.env.REACT_APP_NEW_PORTAL_URL;
            return;
          }
        })
        .catch((error) => {
          window.location.href = process.env.REACT_APP_NEW_PORTAL_URL;
          return;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, sessionId]);

  return (
    <div className="spinner-background">
      <div className="container spinner-background">
        <div className="mx-auto d-block justify-content-center text-center mt-4">
          <h2>We're signing you into your portal...</h2>
        </div>
        <div className="mx-auto d-block justify-content-center text-center mt-3">
          <img src={spinner} alt="spinner" />
        </div>
      </div>
    </div>
  );
};

export default withOktaAuth(Welcome);
