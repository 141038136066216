import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import InvoiceLogo from "../images/invoice-logo.png"
import "./InvoicePayLink.css";

const InvocePayLink = ({ userInfo }) => {
  const [showPopup, setShowPopu] = useState(false);

  const toggleShow = () => setShowPopu(!showPopup);
  showPopup ? document.body.classList.add("active-modal") : document.body.classList.remove("active-modal");

  return (
    <>
      {userInfo?.ShowPayInvoice && (
        <div className="container">
          <div className="invoice">
            <Link onClick={toggleShow} to="#" className="invoice-link">
              <img src={InvoiceLogo} alt="Invoice-logo" /> <span>Pay Invoice</span>
            </Link>
          </div>
        </div>
      )}
      {showPopup && (
        <div style={{border:'1px solid #ffffff', marginTop:0, }}>
          <div onClick={toggleShow} className="overlay"></div>
          <div className="modal-contents">
            <div className="constents-div">
              <p>You are being directed to an external site to make your payment.</p>
              <p>Please have your invoice group number and phone number associated with your account(s).</p>
              <p>If you have already made payment or set up autopayment, payment is not due.</p>
            </div>
            <a onClick={() => setShowPopu(!showPopup)} className="onlinepay-btn" href="https://onlinepay.hightoweradvisors.com" target="_blank" rel="noopener noreferrer">
              Continue
            </a>
          </div>
        </div>
      )}
    </>
  );
};
export default InvocePayLink;
