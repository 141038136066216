import http from "./httpService";
import SendMessageToRoom from "./postWebexMessageService";
class MenuService {
  static getData(userId, sessionId) {
    const myPromise = new Promise((resolve, reject) => {
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/users/" + userId + "/menuItemsV2";
      let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
      var getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"))
      const getUserAccessToken = getUserToken.accessToken;
      const updatedUserToken = getUserAccessToken.accessToken;
      const Email = getUserAccessToken.claims.sub;

      http.get(endpoint, {
        headers: {
          'token': updatedUserToken,
          'client_id': clientId
        }
      }).then(response => {
        const items = response.data;
        resolve(items);
        console.log('MenuService', items)
      })
        .catch((error) => {
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO RETRIEVE MENU ITEMS

            Domain Name: ${window.location.host} 
            Client Email: ${Email}
            Service: MenuService 
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error.response.status : 'Unknown'}
            Error Message: ${error.response.data.message}.`,
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });
    return myPromise;
  }
}

export default MenuService;
