import { useState } from 'react';
import './Demo.css';
import mypro from './my-pro.png';
import fidelity from './fidelity.png';
import CharlesShiwab from './Charles_Schwab.png';
import { AiOutlineSchedule } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { GoListUnordered } from "react-icons/go";
import { AiOutlineEye } from "react-icons/ai";
import { CgCalendarNext } from "react-icons/cg";
import DonutChart from './DonutChart';

const Demo = () => {
    const [percentage, setPercentage] = useState(75);
    const [todos, setTodos] = useState([
        {
            id: 1,
            head: 'Update Personal Information',
            task: "To Do",
            body: "Lorem ipsum dolor sit amet consectetur.",
            completed: true,
            showPopup: false,

        },
        {
            id: 2,
            head: 'Send Account Statements',
            task: "To Do",
            body: "Lorem ipsum dolor sit lakd amet atque ipsa.",
            completed: false,
            showPopup: false,

        },
        {
            id: 3,
            head: 'Review Investment Proposal',
            task: "Meeting",
            body: "Lorem ipsum hghggh  libero",
            completed: false,
            showPopup: false,

        },
        {
            id: 4,
            head: 'Account Set Up',
            task: "To Do",
            body: "Lorem ipsum   eius molestias omnis libero, atque ipsa.",
            completed: false,
            showPopup: false,

        },
        {
            id: 5,
            head: 'Tax Planing Meeting',
            task: "Meeting",
            body: "Lorem ipsum dolor  omnis libero, atque.",
            completed: false,
            showPopup: false,

        },
    ]);
    const data = [
        { id: 1, docName: 'Portfilio Review', report: "report" },
        { id: 2, docName: 'Portfilio Review', report: "report" },
        { id: 3, docName: 'Portfilio Review', report: "report" },
        // Add more data as needed
    ];
    const handleLinkClick = (id) => {
        console.log(id)
        const updatedTodos = [...todos];

        // Find the todo with the given id
        const todo = updatedTodos.find(item => item.id === id);
        console.log(todo, updatedTodos)
        // Toggle the pop-up visibility
        todo.showPopup = !todo.showPopup;


        // Update the state with the modified todos
        setTodos(updatedTodos);
    };
    const handleCompletedClick = (id) => {
        // Create a copy of the todos array
        const updatedTodos = [...todos];

        // Find the todo with the given id
        const todo = updatedTodos.find(item => item.id === id);

        // Mark the task as completed
        todo.completed = true;

        // Hide the pop-up
        todo.showPopup = false;

        // Update the state with the modified todos
        setTodos(updatedTodos);
    };
    const todoItems = todos.map(todo => (
        <div key={todo.id} className="todo-item" style={{ position: 'relative' }}>

            <div className={`circle ${todo.completed ? 'completed' : ''}`}>
                <p style={{ marginBottom: "50px", fontSize: '14px', marginLeft: '20px' }}>7/15</p>
                {todo.completed}
            </div>
            <br />
            <h3 className="todo-task"><span style={{ marginRight: "5px", color: "black" }}><GoListUnordered /></span>{todo.task}</h3>
            <h2 className="todo-head">{todo.head}</h2>
            <p className='todo-body'>{todo.body}</p>
            {todo?.completed ?
                <p className="todo-text" style={{ color: 'rgb(5, 239, 64)' }}><span className="checkmark">&#10003;</span>Completed</p> :
                <>
                    <a href='#1' className="todo-text" onClick={() => handleLinkClick(todo.id)}>Link</a>
                    {todo?.showPopup && (
                        <div className="popup">
                            <p>Lorem eos eligendi doloremque nam explicab tempore! Alias eveniet maiores deserunt mollitia error, in voluptas laudantium consequuntur.</p>
                            <button onClick={() => handleCompletedClick(todo.id)}>Completed</button>
                        </div>
                    )}
                </>
            }
        </div>
    ));


    return (
        <div className="grid-container">
            <div className="grid-item first-row">
                <div className="profile">
                    <img src={mypro} alt="Profile" className="profile-image" />
                    <h2>Michael Bankston</h2>
                    <h4>Financial Advisor</h4>
                    <a href="#1">
                        <span className='icon-span'><AiOutlineSchedule style={{ color: '#fff' }} /> </span> Schedule a Meeting
                    </a>
                </div>
                <div className="buttons">
                    <span ><BsTelephoneFill className='call' />123-456-7270</span>
                    <span ><MdEmail className='call' />m.madison@xyz.com</span>
                </div>
                <a href="#1" className="link">Go to Final term page</a>
            </div>
            <div className="grid-item remaining-width"  >
                <h2 >Your Touchpoints</h2>
                <div>
                    {todoItems}
                </div>
            </div>
            <div className="grid-item donat-percent">
                <h2>Probability of Success</h2>
                <p>As of Aug 2/2023 <input min={0} max={100} style={{width:"40px", marginLeft:"10px"}} type="number" value={percentage} onChange={(e)=>setPercentage(e.target.value)}/></p>
                <div className="donut-chart">
                <DonutChart percentage={percentage} label="Moderate Confidence Zone" />
                </div>
                <a href="#1" className="link">Go to Final term page <CgCalendarNext /></a>
            </div>
            <div className="grid-item "> </div>
            <div className="grid-item  item-3">
                <div>
                    <h2>Portifolio Analytics</h2>
                    <ul>
                        <li>portifolio Allocations</li>
                        <li>Account Holdings and Returns</li>
                    </ul>

                </div>
                <a href="#1" className="">Go to Final term page <CgCalendarNext /></a>
            </div>
            <div className="grid-item gain-loss">
                <h2>Gain Loss</h2>
                <h4>Total</h4>
                <h2>0.0</h2>
                <div>
                    <h2>Unrealized</h2>
                    <h2>0.00</h2>
                </div>
                <div>
                    <p>Short Term</p>
                    <span>0.00</span>
                </div>
                <div>
                    <p>Long Term</p>
                    <span>0.00</span>
                </div>
                <div style={{ color: "black", width: "100%", borderTop: '1px solid #7a7979' }}></div>

                <div>
                    <h2>Realized</h2>
                    <h2>0.00</h2>
                </div>
                <div>
                    <p>Short Term</p>
                    <span>0.00</span>
                </div>
                <div>
                    <p>Long Term</p>
                    <span>0.00</span>
                </div>
                <a href="#1" className="gl-link">See Gain Loss Datails</a>
            </div>
            <div className="grid-item "></div>
            <div className="grid-item "></div>
            <div className="grid-item "></div>
            <div className="grid-item  quick-link">
                <h2>Quick Links</h2>
                <div>
                    <div className='q-content'>
                        <img src={fidelity} alt="Fidelity" />
                        <div>
                            <h3>Fidelity</h3>
                            <a href="#1">Go to Fidelity Portal <CgCalendarNext /></a>
                        </div>
                    </div>
                    <div style={{ color: "black", width: "100%", margin: "15px 0", borderTop: "3px solid #d8d6d6" }}></div>
                    <div className='q-content'>
                        <img src={CharlesShiwab} alt="Charles Schwab" />
                        <div>
                            <h3>Charles Schwab</h3>
                            <a href="#1">Go to Charles Schwab Portal <CgCalendarNext /></a>
                        </div>
                    </div>
                </div>


            </div>
            <div className="grid-item  recent">
                <h2>Recently Uploaded Documents</h2>
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Document Name</th>
                            <th>Document Type</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.id} className="styled-row">
                                <td>{item.docName}</td>
                                <td>{item.report}</td>
                                <td><AiOutlineEye style={{ fontSize: '25px', color: '#5baff5' }} /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <a href="#1">Go to Document Vault</a>

            </div>
            <div className="grid-item news ">
                <h2>News</h2>
                <div className="news-content">
                    <h2> edignissimos  error blanditiis safah dkjdopnfd piente?</h2>
                    <p>Lorem ipsum dolor sit adds odfnd die.ndlkj dsf ldo  dkljld ut possimus ducimus.</p>
                    <a href="#1">Go to Article <CgCalendarNext /></a>
                </div>
                <div style={{ color: "black", width: "100%", margin: "15px 0", borderTop: "3px solid #d8d6d6" }}></div>
                <div className="news-content">
                    <h2> edignissimos  error blanditiis safah dkjdopnfd piente?</h2>
                    <p>Lorem ipsum dolor sit adds odfnd die.ndlkj dsf ldo  dkljld ut possimus ducimus.</p>
                    <a href="#1">Go to Article <CgCalendarNext /></a>
                </div>
            </div>
        </div>
    );
};

export default Demo;