import React, { useState, useEffect } from "react";
import { withOktaAuth } from "@okta/okta-react";
import LegalTermsService from "../services/legalTermsService";
import HTLogo from "../images/hightower-logo.png";
import Footer from "./footer";
import "./LegalTerms.css";

const LegalTerms = () => {
  const [legalterms, setLegalterms] = useState([]);

  useEffect(() => {
    document.title = "Legal Terms of Use";
    LegalTermsService.getLegalTerms()
      .then((resData) => {
        setLegalterms(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const legaltermsText = legalterms && legalterms.articleConsent;
  return (
    <>
      {legalterms &&
        <>
          <div className="header row">
            <div className="container">
              <div className="header row align-items-center justify-content-end">
                <div className="col logo">
                  <img src={HTLogo} height="80" alt="Hihgh Tower" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="legal-content-container">
              <div className="container-fluid element-container default ht-gray-stripe-fill min-height-50 none"></div>
              <h2 className="terms-head">Terms Of Use</h2>
              <div className="mt-4 terms-body" dangerouslySetInnerHTML={{ __html: legaltermsText }} />
            </div>
          </div>
        </>
      }
      <div className="legal-footer-container">
        <Footer />
      </div>
    </>
  );
};

export default withOktaAuth(LegalTerms);