import axios from "axios";
import SendMessageToRoom from "./postWebexMessageService";

class LegalTermsService {
  static getLegalTerms() {
    const myPromise = new Promise((resolve, reject) => {
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/legal-terms";
      const brandPrefix = sessionStorage.getItem("brandPrefix");
      const Email = JSON.parse(localStorage.getItem("Users"))?.Email;
      axios
        .get(endpoint)
        .then((response) => {
          const respData = response.data;
          resolve(respData);
        })
        .catch((error) => {
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO LOAD LEGAL TERMS

            Domain Name: ${brandPrefix} 
            Client Email: ${Email} 
            Service: LegalTermsService
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error.response.status : 'Unknown'}
            Error Message: ${error.response.data.message}.`
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });
    return myPromise;
  }
}


export default LegalTermsService;
