import axios from "axios";
import SendMessageToRoom from "./postWebexMessageService";

class PershingDocumentsService {
  static getReports( ) {
    const getOktaID = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.claims.uid;
    const Email = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.claims.sub;
    const accessToken = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.accessToken;
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const endpoint = process.env.REACT_APP_PERSHING_REPORTS;
    let pershingEndpoint = `${endpoint}${getOktaID}/documents/pershing`;
 
    const allPromise = new Promise((resolve, reject) => {
      axios
        .get(pershingEndpoint, {
          headers: {
            token: accessToken,
            client_id: clientId,
          },
        })
        .then((statments) => {
          resolve(statments.data);
        })
        .catch((error) => {
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO RETRIEVE PERSHING REPORTS
            
            Domain Name: ${window.location.host} 
            Client Email: ${Email}
            Service: PershingDocumentsService 
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error.response.status : 'Unknown'}
            Error Message: ${error.response.data.message}.`,
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });

  
    return { allPromise };
  }
}

export default PershingDocumentsService;
