import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import uuid from "react-uuid";
import { Accordion, Form, Table, Spinner } from "react-bootstrap";
import { MdOutlineFolderOpen } from "react-icons/md";
import { BsFileEarmarkFill, BsFillEyeFill } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import pershingPdfReports from "../../../services/pershingPdfReports";
import PdfPreview from "./pdfPreview";

const StatementComponent = ({ data, formatedDate }) => {  
    const [loadingIndex, setLoadingIndex] = useState(null);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState();
    const [pdfFileName, setPdfFileName] = useState("");
    const [showPDF, setShowPDF] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState("all");
    const [selectedYear, setSelectedYear] = useState("all");
    const [selectedQuarter, setSelectedQuarter] = useState("all");
    const [searchAccount, setSearchAccount] = useState("");
    const uniqueAccounts = [...new Set(data?.map((doc) => doc.ACCT))];
    const uniqueYears = [...new Set(data?.map((doc) => doc.STATEMENT_DATE.substring(6)))];
    const [currentPage, setCurrentPage] = useState(0);

    const handleFilter = () => {
        let filteredData = data;
        if (selectedAccount !== "all") {
            filteredData = filteredData.filter((doc) => doc.ACCT === selectedAccount);
        }
        if (selectedYear !== "all") {
            filteredData = filteredData.filter((doc) => doc.STATEMENT_DATE.includes(selectedYear));
        }
        if (selectedQuarter !== "all") {
            const quarterMonths = {
                q1: ["01", "02", "03"],
                q2: ["04", "05", "06"],
                q3: ["07", "08", "09"],
                q4: ["10", "11", "12"],
            };
            filteredData = filteredData.filter((doc) => {
                const docMonth = doc.STATEMENT_DATE.substring(0, 2);
                const docQuarter = Math.ceil(docMonth / 3);
                return (
                    doc.STATEMENT_DATE.substring(6) === selectedYear &&
                    docQuarter.toString() === selectedQuarter.substring(1) &&
                    quarterMonths[selectedQuarter]?.includes(docMonth)
                );
            });
        }
        if (uniqueAccounts.length > 5 && searchAccount.trim() !== "") {
            filteredData = filteredData.filter((doc) => doc.ACCT.toLowerCase().includes(searchAccount.toLowerCase()));
        }
        return filteredData;
    };
    const handlePageChange = (selected) => {
        setCurrentPage(selected.selected);
    };
    const filteredData = handleFilter();
    const totalPages = Math.ceil(filteredData.length / 5);
    const offset = currentPage * 5;
    const paginatedData = filteredData.sort((a, b) => new Date(b.STATEMENT_DATE) - new Date(a.STATEMENT_DATE)).slice(offset, offset + 5);
    
    const showDownloadPDF = async (Id, name, i, accounttype) => {
        setDownloadLoading(true);
        setLoadingIndex(i);
        const response = await pershingPdfReports.getReports(Id, accounttype);
        response.getPDF
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement("a");
                link.href = url;
                setDownloadLoading(false);
                link.setAttribute("download", `${name}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
                setDownloadLoading(false);
            });
    };

    const showPreviewPDF = async (Id, name, i, accounttype) => {
        setPreviewLoading(true);
        setPdfFileName(name);
        setLoadingIndex(i);
        const response = await pershingPdfReports.getReports(Id, accounttype);
        response.getPDF
            .then((response) => {
                // console.log("Pershing pdf res: ", response);
                setPreviewLoading(false);
                setPdfUrl(response);
                setShowPDF(true);
            })
            .catch((err) => {
                console.log(err);
                setPreviewLoading(false);
            });
    };

    return (
        <>
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <span className="accordion-heading">
                        <MdOutlineFolderOpen className="icon" />
                        Custodial Statements ( {filteredData?.length < data.length ? (filteredData?.length + " / " + data.length) : data.length})
                    </span>
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        <div className="row">
                            <div className="col-md-6" ></div>
                            <div className="col-md-6" >
                                <div className="filter-container" >
                                    <div className="mr-3 d-flex">
                                        ACCT
                                        {uniqueAccounts.length > 12 ? (
                                            <input
                                                className="form-account"
                                                type="text"
                                                style={{ width: "150px", height: "28px", border: 'none', textAlign: 'center', marginLeft: '10px' }}
                                                value={searchAccount}
                                                onChange={(e) => setSearchAccount(e.target.value)}
                                                placeholder="Search By Account"
                                            />
                                        ) : (
                                            <Form.Select
                                                aria-label="Default select"
                                                value={selectedAccount}
                                                onChange={(e) => setSelectedAccount(e.target.value)}
                                            >
                                                <option value="all">All</option>
                                                {uniqueAccounts &&
                                                    uniqueAccounts
                                                        .sort((a, b) => b - a)
                                                        .map((year) => (
                                                            <option key={uuid()} value={year}>
                                                                {year}
                                                            </option>
                                                        ))}
                                            </Form.Select>
                                        )}
                                    </div>
                                    <div className="mr-3 d-flex">
                                        Year
                                        <Form.Select aria-label="Default select" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                            <option value="all">All</option>
                                            {uniqueYears &&
                                                uniqueYears
                                                    .sort((a, b) => b - a)
                                                    .map((year) => (
                                                        <option key={uuid()} value={year}>
                                                            {year}
                                                        </option>
                                                    ))}
                                        </Form.Select>
                                    </div>
                                    <div className="d-flex">
                                        Quarter
                                        <Form.Select aria-label="Default select" value={selectedQuarter} onChange={(e) => setSelectedQuarter(e.target.value)}>
                                            <option value="all">All</option>
                                            {selectedYear !== "all" && (
                                                <>
                                                    <option value="q1">Q1</option>
                                                    <option value="q2">Q2</option>
                                                    <option value="q3">Q3</option>
                                                    <option value="q4">Q4</option>
                                                </>
                                            )}
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table">
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>ACCOUNT</th>
                                        <th>STATEMENT DATE</th>
                                        <th>PAGES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map((doc, i) => (
                                        <tr key={uuid()}>
                                            <td>
                                                <BsFileEarmarkFill className="grid-icons mr-1" />
                                                {doc.SHORT_NAME}{" - "}{doc.ACCOUNT_TYPE}
                                            </td>
                                            <td>{doc.ACCT}</td>
                                            <td>{formatedDate(doc.STATEMENT_DATE)}</td>
                                            <td>{doc.PAGECNT}</td>
                                            <td>
                                                {(!previewLoading || loadingIndex !== i) && <BsFillEyeFill className="grid-icons pdfViwer" onClick={() => showPreviewPDF(doc.DOC_ID, doc.SHORT_NAME, i, doc.ACCOUNT_TYPE)} />}
                                                {!!previewLoading && loadingIndex === i && <Spinner animation="border" size="sm" />}
                                            </td>
                                            <td>
                                                {(!downloadLoading || loadingIndex !== i) && (
                                                    <HiDownload
                                                        className="grid-icons pdfViwer"
                                                        onClick={() => {
                                                            showDownloadPDF(doc.DOC_ID, doc.SHORT_NAME, i, doc.ACCOUNT_TYPE);
                                                        }}
                                                    />
                                                )}
                                                {!!downloadLoading && loadingIndex === i && <Spinner animation="border" size="sm" />}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {totalPages === 0 && <p className="no-q">No Document For This Account At The Selected Year and Quarter</p>}
                            {totalPages > 1 &&
                                <div className="pagination">
                                    <ReactPaginate
                                        nextLabel="►"
                                        previousLabel="◄"
                                        onPageChange={handlePageChange}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={totalPages}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName={currentPage === 0 ? "page-item disabled" : "page-item"}
                                        previousLinkClassName="page-link"
                                        nextClassName={currentPage === totalPages - 1 ? "page-item disabled" : "page-item"}
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        disableNextButton={currentPage === totalPages - 1}
                                        disablePrevButton={currentPage === 0}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            {showPDF && pdfUrl && <PdfPreview setShowPDF={setShowPDF} pdfUrl={pdfUrl} pdfFileName={pdfFileName} />}
        </>
    );
};
export default StatementComponent;