import axios from 'axios';

const apiUrl = process.env.REACT_APP_APIGATEWAY_WEBEX_ERROR_LOGGER
const SendMessageToRoom = (message) => {
  const updatedUserToken = JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken?.accessToken;

  axios.post(apiUrl, message, {
    headers:
    {
      'Authorization': updatedUserToken,
      'Content-Type': 'application/json',    }
  })
    .then((response) => {
      if (response.status === 200) {
        console.log('Message sent successfully: ', response.data);
      } else {
        console.error('Failed to send message: ', response.data);
      }
    })
    .catch((error) => {
      console.error('Error sending message: ', error);
    });
};

export default SendMessageToRoom;