import React, { useEffect, useRef } from 'react';

const DonutChart = ({ percentage = 0, label = "" }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = 120;
        const lineWidth = 25;

        const startAngle = Math.PI; // Start at the left
        const endAngle = Math.PI + Math.PI; // Always create a 180-degree semicircle

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width , canvas.height);

        // Draw the border of the semicircle
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, startAngle, endAngle);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "#ccc"; // Color of the border
        ctx.stroke();

        // Calculate the filled portion based on the percentage
        const filledAngle = Math.PI + (percentage / 100) * Math.PI;

        // Draw the filled semicircle based on the percentage
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, startAngle, filledAngle);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = "blue"; // Color of the filled semicircle
        ctx.stroke();

        // Add text above the semicircle (e.g., the percentage)
        ctx.fillStyle = "#333"; // Text color
        ctx.font = "20px Arial"; // Text font
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom"; // Align text to the bottom (just above the semicircle)
        ctx.fillText(`${percentage}%`, centerX, centerY - lineWidth / 2 - 5); // Adjust the vertical position of the text

        // Add label under the x-axis
        ctx.fillStyle = "#333"; // Label text color
        ctx.font = "16px Arial"; // Label text font
        ctx.textAlign = "center";
        ctx.textBaseline = "top"; // Align text to the top (just below the x-axis)
        ctx.fillText(label, centerX, centerY + lineWidth / 2 + 5); // Adjust the vertical position of the label

        canvas.style.display = "block";
        canvas.style.margin = "auto";
    }, [percentage, label]);

    return <canvas ref={canvasRef} width="300" height="300" ></canvas>;
};

export default DonutChart;
