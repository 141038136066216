import axios from "axios";
import SendMessageToRoom from "./postWebexMessageService";

class News {
  static getNews(userId) {

    const Domain =  JSON.parse(localStorage.getItem("advisor"))?.Domain;
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const updatedUserToken = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.accessToken;
    const Email = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.claims.sub;
    const endpoint = `${process.env.REACT_APP_MULESOFT_HOST}/api/users/${userId}/advisor/news/${Domain}`;

    const myPromise = new Promise((resolve, reject) => {
      axios.get(endpoint, {
        headers: {
          'token': updatedUserToken,
          'client_id': clientId
        }
      }).then(response => {
        localStorage.setItem("news", JSON.stringify(response.data));
        resolve(response.data);
      }).catch(error => {
        const messagePayload = {
          message: `
          UNABLE TO RETRIEVE PERSHING PDF REPORT
          
          Domain Name: ${window.location.host} 
          Client Email: ${Email}
          Service: pershingPdfReports 
          Mulesoft Endpoint: ${endpoint} 
          SalesForce Advisor Domain: ${Domain}
          Status Code: ${error.response ? error.response.status : 'Unknown'}
          Error Message: ${error?.message}.`,
        };
        SendMessageToRoom(messagePayload);        
        reject(error);
      });
    });
    return myPromise;
  }
}

export default News;