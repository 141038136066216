import { useAccordionButton } from "react-bootstrap";
//import Button from "react-bootstrap/Button";

const CustomBackToggle = (props) => {
  const { eventKey, handleCollapse, backBtnRef } = props;
  const handleBackBtn = useAccordionButton(eventKey, () => handleCollapse());

  return (
    <div ref={backBtnRef} style={{ display: "none" }} onClick={handleBackBtn} className="btn-home" id="button">
      Back
    </div>
  );
};

export default CustomBackToggle;
