import React, { useState } from "react";

const AdvisorWide = (props) => {
  const [advisor] = useState(props.advisor);
  const photoStr = "Photo";
  const brandDomain = sessionStorage.getItem("brandPrefix"); 
  return (
    <div className="row">
      <div className="col-12">
        <div className="card-horizontal">
          <div className="row advisor-content align-items-center">
            <div className="col-12 col-md-auto d-flex justify-content-center">
              {advisor.Id !== undefined && advisor.Id !== null ? (
                <img src={process.env.REACT_APP_ADVISOR_CLOUDFRONT + "/" + advisor.Id + ".png"} alt={`Advisor's ${photoStr}`} className="ellipse" />
              ) : (
                <img src={require("../../images/clientPortal/defaultAvatar.png").default} alt={advisor.Name} className="ellipse img-fluid" />
              )}
            </div>
            <div className="col-12 col-md">
              <div className="row py-3">
                <div className="col-md-7 col-lg text-center text-md-left">
                  <div className="advisorName">{advisor.Name}</div>
                  <div className="title">{advisor.Title}</div>
                </div>
                <div className="col-md-5 col-lg-auto d-none d-md-block">
                  {advisor.ScheduleMeetingLink && (
                    <div className="d-flex schedule-wide mt-3 mt-lg-0">
                      <a href={advisor.ScheduleMeetingLink} rel="noreferrer" target="_blank">
                        <img src={require("../../images/clientPortal/Schedule.png").default} alt="Advisor's Schedule Icon" />
                        <span>Schedule</span>
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 advisor-address align-items-center">
                  <div className="row flex-column flex-md-row">
                    {brandDomain !== 'schultzcollins' && <div className="col-12 col-md-auto py-2 py-md-0 d-flex align-items-start email-text" >
                      <img className="contact-icon" src={require("../../images/clientPortal/Email.png").default} alt="Advisor's Email Icon" />
                      <span>
                        <a className="contact" href={`mailto:${advisor.Email}`}>
                          {advisor.Email}
                        </a>
                      </span>
                    </div>}
                    <div className="col-12 col-md-auto  d-flex align-items-start">
                      <img className="contact-icon" src={require("../../images/clientPortal/Phone.png").default} alt="Advisor's Phone Icon" />
                      <span>
                        <a className="contact" href={`tel:${advisor.Phone}`}>
                          {advisor.Phone}
                        </a>
                      </span>
                    </div>
                    <div className="col-12 d-md-none justify-content-center pt-4">
                      {advisor.ScheduleMeetingLink && (
                        <div className="schedule d-flex m-auto">
                          <a href={advisor.ScheduleMeetingLink} rel="noreferrer" target="_blank">
                            <img src={require("../../images/clientPortal/Schedule.png").default} alt="schedule" />
                            <span>Schedule</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisorWide;