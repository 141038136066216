import http from "./httpService";
import SendMessageToRoom from "./postWebexMessageService";

class PdfReportsService {
  static getPDFFile(bdportalID, reportId, sessionId) {
    const myPromise = new Promise((resolve, reject) => {
      var getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"));
      const getUserAccessToken = getUserToken.accessToken;
      const updatedUserToken = getUserAccessToken.accessToken;
      let oktaID = JSON.parse(localStorage.getItem("okta-token-storage"))
      const getOktaID = oktaID.accessToken.claims.uid
      let endpoint = process.env.REACT_APP_PERFORMANCE_REPORTS + `report/${getOktaID}/${reportId}`;
      let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
      const Email = getUserAccessToken.claims.sub;

      http
        .get(endpoint, {
          responseType: "blob",
          headers: {
            token: updatedUserToken,
            client_id: clientId,
          },
        })
        .then((response) => {
          const respData = response.data;
          resolve(respData);
        })
        .catch((error) => {
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO RETRIEVE PDF REPORT
            
            Domain Name: ${window.location.host} 
            Client Email: ${Email}
            Service: PdfReportsService 
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error.response.status : 'Unknown'}
            Error Message: ${error.response.data.message}.`,
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });
    return myPromise;
  }
}

export default PdfReportsService;
