import React, { useState, useRef } from "react";
import Button from "react-bootstrap/esm/Button";
import { useHistory } from "react-router-dom";
import "./legalTermsPopup.css";
import { useEffect } from "react";
import LegalTermsService from "../services/legalTermsService";
import UserService from "../services/userService";
import ConsentLogService from "../services/consentLogService";
import { useOktaAuth } from "@okta/okta-react";

const LegalTermsconditions = (props) => {
  const [value, setValue] = useState(true);
  const [legalterms, setLegalterms] = useState([]);
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();

  const { setLoading } = props;
  useEffect(() => {
    setLoading(false);
  }, [setLoading]);


  const onSignOut = async () => {
    sessionStorage.clear();
    oktaAuth.tokenManager.clear();
    await oktaAuth
      .signOut()
      .then(function () {
        localStorage.clear();
        sessionStorage.clear()
        history.push({ pathname: "/login" });
      })
      .catch(function (err) { });
  };
  const handleAgreedOrCancel = (isAgreed) => {

    if (isAgreed === false) {
      onSignOut();
    } else {

      UserService.updateData(props.userId, isAgreed)
        .then((resData) => {
          UserService.getData(props.userId).then((res) => {
            localStorage.setItem("Users", JSON.stringify(res));
            history.push("/menu")
            props.setIsConsentAccepted(true);
          }).catch((err) => {
            console.log(err);
            history.push("/error");
          });

          ConsentLogService.getConsentLog(props.userId, isAgreed, legalterms?.versionNumber)
            .then((resData) => {
              console.log("consent log submitted", resData);
            })
            .catch((err) => {
              console.log(err);
              
            });
        })
        .catch((err) => {
          console.log(err);
          history.push("/error");
        });
    }


  };

  useEffect(() => {
    LegalTermsService.getLegalTerms()
      .then((resData) => {
        setLegalterms(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const legaltermsText = legalterms && legalterms.articleConsent;

  const listInnerRef = useRef();
  const onScroll = (e) => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      let ScollTopClientHeight = scrollTop + clientHeight;

      let floorScollTopClientHeight = Math.floor(scrollTop + clientHeight);

      let ceilScollTopClientHeight = Math.ceil(scrollTop + clientHeight);

      if (ScollTopClientHeight === scrollHeight || floorScollTopClientHeight === scrollHeight || ceilScollTopClientHeight === scrollHeight) {
        setValue(false);
      }
    }
  };
  const iAgreeBtnClass = value ? "secondary" : "success";
  return (
    <div style={{ height: 0 }}>
      <div className="overlay-l"></div>
      <div className="modal-content-l" >
        <h2>Terms of Use</h2>
        <div onScroll={onScroll} ref={listInnerRef} className="term-content" dangerouslySetInnerHTML={{ __html: legaltermsText }} />
        <div className="btn-agree" >
          <Button variant={iAgreeBtnClass} disabled={value} className="p-btn" onClick={() => handleAgreedOrCancel(true)}>
            I Agree
          </Button>
          <Button variant="secondary" onClick={() => handleAgreedOrCancel(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
export default LegalTermsconditions;