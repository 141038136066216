import { useState, useEffect } from "react";
import "./pagination.css";
import TaxComponent from "./taxComponent";
import spinner from "../../../images/ajax-loader.gif";
import { withOktaAuth } from "@okta/okta-react";
import PershingDocumentsService from "../../../services/pershingDocumentsService";
// import taxMockData from "../../../services/pershingMockData";
//import statmentMockData from "../../../services/pershingMockData";
import StatementComponent from "./statementComponent";
import { useHistory } from "react-router-dom";

const Pershing = ({ setNoPershing, boxURL }) => {
  const [tax, setTax] = useState([]);
  const [statment, setStatments] = useState([]);
  const [isTaxLoading, setIsTaxLoading] = useState(true);
  const [isStatmentLoading, setIsStatmentLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear() - 1);
  const history = useHistory();
  ////////////////////////////////////format date/////////////////////////////////
  const formatedDate = (value) => new Date(value).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }).replace(/\//g, "-");
  ///////////////////////////////////////////////////////////////////////////////
  const PershingActivated = JSON.parse(localStorage.getItem("Users")).PershingActivated

  useEffect(() => {
    console.log("PershingActivated check", PershingActivated)
    if (!PershingActivated) {
      setNoPershing(true);
    } else {
      let mounted = true;
      setIsStatmentLoading(true);
      setIsTaxLoading(true);

      const getPershingStatements = async () => {
        if (localStorage.getItem("pershing-docs")) {
          const data = JSON.parse(localStorage.getItem("pershing-docs"));
          console.log("no docs", data?.taxdocuments?.documents?.length, data?.statements?.documents?.length)
          if (data?.taxdocuments?.documents?.length === 0 && data?.statements?.documents?.length === 0) {
            setNoPershing(true);
          }
          setStatments(data?.statements);
          setTax(data?.taxdocuments);
          setIsStatmentLoading(false);
          setIsTaxLoading(false);
          return;
        } else {
          try {
            const response = await PershingDocumentsService.getReports(selectedYear);
            response.allPromise
              .then((response) => {
                if (mounted) {

                  localStorage.setItem("pershing-docs", JSON.stringify(response));
                  setStatments(response?.statements);
                  setTax(response?.taxdocuments);
                  setIsStatmentLoading(false);
                  setIsTaxLoading(false);
                  if (response?.taxdocuments?.documents?.length === 0 && response?.statements?.documents?.length === 0) {
                    setNoPershing(true);
                  }
                }
              })
              .catch((error) => {
                console.error(error);
                setIsStatmentLoading(false);
                setIsTaxLoading(false);
                window.open(boxURL, '_blank');
                history.push('/menu'); 
              });
          } catch (error) {
            console.error(error);
          }
        }
      };

      getPershingStatements();
      return () => {
        // Cleanup function: Set mounted to false when the component unmounts
        mounted = false;
      };
    }
  }, [selectedYear, setNoPershing, PershingActivated, boxURL, history]);

  return (
    <>
      {PershingActivated &&

        <>
          {isTaxLoading || isStatmentLoading ? (
            <div className="spinner-background">
              <div className="container spinner-background">
                <div className="mx-auto d-block justify-content-center text-center mt-4">
                  <img src={spinner} alt="spinner" />
                </div>
              </div>
            </div>
          ) : (
            <>
              {tax && tax?.documents?.length > 0 && <TaxComponent data={tax} formatedDate={formatedDate} setSelectedYear={setSelectedYear} />}
              {statment && statment?.documents?.length > 0 && <StatementComponent data={statment?.documents} formatedDate={formatedDate} />}
            </>
          )}
        </>
      }
    </>
  );
};

export default withOktaAuth(Pershing);
