import { BiChevronDown, BiChevronUp } from "react-icons/bi";
const photoStr = "Photo";

const AdvisorsList = (props) => {
  const { advisor, size, showContactDetail, hideAdvisorDetial, showAdvisorDetial, uid, numAdvisors } = props;
  const brandDomain = sessionStorage.getItem("brandPrefix");

  return (
    <div  className={ numAdvisors===2 && size < 700 && size>459? "advisors-grid advisors-grid-height-2": size <1001 ? "advisors-grid advisors-grid-height":size>1200?"advisors-grid ad-1200" : "advisors-grid"} >
      <div className={advisor === showContactDetail ? "position-absolute overlaping" : "no-overlap"}>
        <div className={size>1200?"info-l":size > 1000 ? "info-2" : size >= 459 || size < 1000 ? "info" : "s-info"}>
          <img className={size > 1000 || size < 459 ? "advisor-img" : "s-advisor-img"} src={process.env.REACT_APP_ADVISOR_CLOUDFRONT + "/" + advisor.Id + ".png"} alt={`Advisor's ${photoStr}`} />
          <div className={size < 1001 ? "s-info-div":size >1200?"l-info-left-1200" :numAdvisors >2? "l-info-left l-info-left-1000": "l-info-left"}>
            <div>
              <h4 id="long-name" className="name" title={advisor.Name.length > 16 ? advisor.Name : ""}>
              {advisor.Name.length > 16 && advisor !== showContactDetail  && numAdvisors > 2 ? advisor.Name.slice(0, 13) + "..." : advisor.Name.length > 23 && advisor !== showContactDetail && numAdvisors === 2 ? advisor.Name.slice(0, 21) + "...": advisor.Name}</h4>
              <p id="long-title" className="name" title={advisor.Title.length > 30 ? advisor.Title : ""}>
                {advisor.Title.length > 30 && advisor !== showContactDetail ? advisor.Title.slice(0, 25) + "..." : advisor.Title}
              </p>
            </div>
            <div>
              {advisor === showContactDetail ? (
                <button className="btn-link t-1" onClick={() => hideAdvisorDetial(uid)}>
                  Contact <BiChevronUp />
                </button>
              ) : (
                <button className=" btn-link t-1" onClick={() => showAdvisorDetial(uid)}>
                  Contact <BiChevronDown />
                </button>
              )}
            </div>
          </div>
        </div>
        {advisor === showContactDetail ? (
          <div>
            <div className="border-top margin-top">
              {brandDomain !== 'schultzcollins' && <div className="col-12 col-md-auto py-2 py-md-0 d-flex align-items-start email-text" >
                <img className="contact-icon" src={require("../../images/clientPortal/Email.png").default} alt="Advisor's Email Icon" />
                <span>
                  <a className="contact" href={`mailto:${advisor.Email}`}>
                    {advisor.Email}
                  </a>
                </span>
              </div>}
              <div className="advisors-contact">
                <img className="contact-icon" src={require("../../images/clientPortal/Phone.png").default} alt="Advisor's Phone Icon" />
                <a className="contact size ml-3 ml-sm-3 ml-md-3 ml-lg-1" href={`tel:${advisor.Phone}`}>
                  {advisor.Phone}
                </a>
              </div>
              {advisor.ScheduleMeetingLink && (
                <div className="schedule s-schedule d-flex m-auto">
                  <a href={advisor.ScheduleMeetingLink} rel="noreferrer" target="_blank">
                    <img src={require("../../images/clientPortal/Schedule.png").default} alt="schedule" />
                    <span>Schedule</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AdvisorsList;