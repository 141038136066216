import axios from "axios";
import SendMessageToRoom from "./postWebexMessageService";

class pershingPdfReports {
  static getReports(id, accounttype) {
    const getOktaID = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.claims.uid;
    const Email = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.claims.sub;
    const accessToken = JSON.parse(localStorage.getItem("okta-token-storage")).accessToken.accessToken;
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const endpoint = process.env.REACT_APP_PERSHING_REPORTS;
    let getPdfEndpoint = `${endpoint}${getOktaID}/documents/pershing/${accounttype}/${id}`;
    const getPDF = new Promise((resolve, reject) => {
      axios
        .get(getPdfEndpoint, {
          responseType: "blob",
          headers: {
            token: accessToken,
            client_id: clientId,
          },
        })
        .then((SingleDoc) => {
          resolve(SingleDoc.data);
        })
        .catch((error) => {
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO RETRIEVE PERSHING PDF REPORT
            
            Domain Name: ${window.location.host} 
            Client Email: ${Email}
            Service: pershingPdfReports 
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error.response.status : 'Unknown'}
            Error Message: ${error?.message}.`,
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });
    return { getPDF };
  }
}

export default pershingPdfReports;
