import http from "./httpService";
import SendMessageToRoom from "./postWebexMessageService";

class UserService {
  static getData(userId) {

    const myPromise = new Promise((resolve, reject) => {
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/users/" + userId;
      let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
      var getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"))
      const getUserAccessToken = getUserToken.accessToken;
      const updatedUserToken = getUserAccessToken.accessToken;
      const Email = getUserAccessToken.claims.sub;

      http
        .get(endpoint, {
          headers: {
            'token': updatedUserToken,
            'client_id': clientId
          }
        })
        .then((response) => {
          const user = response.data;
          
          localStorage.setItem('Users', JSON.stringify(response.data))
          resolve(user);
        })
        .catch((error) => {
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO RETRIEVE USER INFORMATION

            Domain Name: ${window.location.host} 
            Client Email: ${Email}
            Service: UserService 
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error?.response?.status : 'Unknown'}
            Error Message: ${error?.response?.data?.message}.`,
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });
    return myPromise;

  }

  static updateData(userId, isAgreed) {
    const myPromise = new Promise((resolve, reject) => {
      let endpoint = process.env.REACT_APP_MULESOFT_HOST + "/api/users/" + userId;
      let clientUpdateId = process.env.REACT_APP_AUTH_CLIENT_ID;
      const getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"))
      const getUserAccessUpdateToken = getUserToken.accessToken;
      const getUpdatedUserToken = getUserAccessUpdateToken.accessToken;
      const Email = getUserAccessUpdateToken.claims.sub;
      const timeStamp = new Date().toISOString();

      const headers = {
        'token': getUpdatedUserToken,
        'client_id': clientUpdateId

      };
      const data = { isConsentagreed: isAgreed, consentdateTime: timeStamp };
      console.log("consentdata", data)
      http
        .patch(endpoint, data, { headers: headers })
        .then((response) => {
          const userId = response.data.id;
          console.log("response", userId)
           resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          const messagePayload = {
            message: `
            UNABLE TO UPDATE USER INFORMATION WITH CONSENT AGREEMENT

            Domain Name: ${window.location.host} 
            Client Email: ${Email}
            Service: UserService 
            Mulesoft Endpoint: ${endpoint} 
            Status Code: ${error.response ? error.response.status : 'Unknown'}
            Error Message: ${error.response.data.message}.`,
          };
          SendMessageToRoom(messagePayload);
          reject(error);
        });
    });
    return myPromise;
  }
}
export default UserService;
