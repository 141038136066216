import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PortfolioRepostsService from "../services/PortfolioRepostsService";
import Quicklink from "./Quicklink";
import News from "./News";
import spinnerImg from "../images/ajax-loader.gif";
const Portal = (props) => {
  const { userId, reportComplete, reportsLoad, userInfo = {} } = props;
  const { BlackDiamondActivated, BDPortalUserId, Name, PershingActivated, PershingDocumentTypes } = userInfo;
  let logos = props.portal?.LogoFile?.split("/");
  var cardClass = `col col-md-${props.colWidth} card-padding min-height-card`;
  const [url, setUrl] = useState(false);
  const [loading, setLoading] = useState(false);

  const [listOfReports, setListOfReports] = useState();
  const [sendToDefaultVault, SetSendToDefaultVault] = useState(false);
  const [authUri, SetAuthUri] = useState();
  const [spinner, setSpinner] = useState(false);

  let startTime;
  let iterationTime;
  let diffTime;
  // function sleep(milliseconds) {
  //   const date = Date.now();
  //   let currentDate = null;
  //   do {
  //     currentDate = Date.now();
  //   } while (currentDate - date < milliseconds);
  // }
  let responseJson
  let secondstatus
  let secondreportlength = 0;
  async function callsecondtimeAPI() {
    let j = 0;
    startTime = new Date().getTime();
    console.log("startTime", startTime);
    diffTime = 1000;

    let clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    let getUserToken = JSON.parse(localStorage.getItem("okta-token-storage"));
    const getUserAccessToken = getUserToken.accessToken;
    const updatedUserToken = getUserAccessToken.accessToken;
    let oktaID = JSON.parse(localStorage.getItem("okta-token-storage"))
    const getOktaID = oktaID.accessToken.claims.uid

    do {
      let endpoint = process.env.REACT_APP_PERFORMANCE_REPORTS + getOktaID
      let response = await fetch(endpoint, {
        headers: {
          token: updatedUserToken,
          client_id: clientId,
        },
      });
      responseJson = await response.json();
      console.log("Response Data in Second Time Fetch", responseJson);
      secondstatus = responseJson.status;
      console.log("responseJson.status", secondstatus);
      localStorage.setItem('List-of-Reports', JSON.stringify(responseJson.listOfReports))
      if (secondstatus !== 'failure' && secondstatus !== null && secondstatus !== '') {
        secondreportlength = responseJson.listOfReports.length
        console.log("responseJson.listOfReports.length", secondreportlength);
      }
      iterationTime = new Date().getTime();
      console.log("iterationTime", iterationTime);
      diffTime = iterationTime - startTime;
      console.log("diffTime-if", diffTime);
      j++
      console.log("iCount", j);

    }
    while (secondstatus === "waiting" && diffTime <= 15000)

    if (secondreportlength > 0) {
      console.log("ifcondition-secondreportlength", secondreportlength);
      logos[logos.length - 1].includes("vault")
        ? history.push({
          pathname: "/AccordionComponent",
          state: {
            boxURL: props.portal.URL,
            userName: Name,
          },
        })
        : setUrl(props.portal.URL);
    } else {
      logos[logos.length - 1].includes("vault")
        ? window.open(props.portal.URL, "_blank")
        : history.push({
          pathname: "/AccordionComponent",
          state: {
            boxURL: props.portal.URL,
            userName: Name,
          },
        });
    }
  }

  const history = useHistory();
  useEffect(() => {
    if (props?.portal?.Title === "Well-th Vault") {
      const report = JSON.parse(localStorage.getItem('report'))
      if (report) {
        setListOfReports(report.listOfReports);
        SetSendToDefaultVault(report.sendToDefaultVault);
        SetAuthUri(report.authUri);
        setLoading(true);
        reportComplete();
      } else {

        PortfolioRepostsService.getReports()
          .then((resData) => {
            localStorage.setItem('report', JSON.stringify(resData))
            localStorage.setItem('List-of-Reports', JSON.stringify(resData.listOfReports))
            localStorage.setItem('pershingDocumentTypes', JSON.stringify(resData.pershingDocumentTypes))
            setListOfReports(resData.listOfReports);

            // const status = resData.status

            SetSendToDefaultVault(resData.sendToDefaultVault);
            SetAuthUri(resData.authUri);
            setLoading(true);
            reportComplete();

            console.log("First Time inside then after setting - full response", resData);
            console.log("First Time inside then after setting - status", resData.status);
            console.log("First Time inside then after setting - listOfReports", resData.listOfReports);

          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  const navigateToAccordian = () => {
    if (spinner) {
      // If spinner is true, return without executing the function
      return;
    }
    setSpinner(true);
    const clientBox = BlackDiamondActivated === "true" ? true : false; // BlackDiamondActivated
    const STATEMENTS = PershingDocumentTypes?.includes("STATEMENTS");
    const TAX_STATEMENTS = PershingDocumentTypes?.includes("TAX_STATEMENTS");
    console.log('===================================================')
    console.log("BlackDiamondActivated", BlackDiamondActivated);
    console.log("clientBox", clientBox);
    // console.lg("listOfReports", listOfReports?.length)
    console.log('authUri', authUri);
    console.log("BDPortalUserId", BDPortalUserId);
    console.log("PershingActivated", PershingActivated);
    console.log("STATEMENTS", STATEMENTS);
    console.log("TAX_STATEMENTS", TAX_STATEMENTS);
    console.log("listOfReports", listOfReports);
    console.log('sendToDefaultVault', sendToDefaultVault)
    console.log('===================================================')
    if (
      ((clientBox || BDPortalUserId) && listOfReports.length > 0)
      ||
      ((authUri === "" || authUri === null) && PershingActivated && (STATEMENTS || TAX_STATEMENTS))
    ) {
      console.log("in 1st if")
      logos[logos.length - 1].includes("vault")
        ? history.push({
          pathname: "/AccordionComponent",
          state: {
            boxURL: props.portal.URL,
            userName: Name,
          },
        })
        : setUrl(props.portal.URL);


    } else if ((clientBox || BDPortalUserId) && sendToDefaultVault === true) {
      console.log("in 2nd if")
      setSpinner(false);
      logos[logos?.length - 1].includes("vault")
        ? window.open(props.portal.URL, "_blank")
        : history.push({
          pathname: "/AccordionComponent",
          state: {
            boxURL: props.portal.URL,
            userName: Name,
          },
        });

    } else if ((clientBox || BDPortalUserId) && (authUri !== "" || authUri !== null)) {
      let authURL = authUri;
      console.log("in 3rd if")
      window.open(
        authURL,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
      );


      callsecondtimeAPI().then(() => {
        setSpinner(false);
      });
      // setSpinner(false);

    } else if (loading) {
      console.log("in last if")
      setSpinner(false);
      logos[logos?.length - 1].includes("vault")
        ? window.open(props.portal.URL, "_blank")
        : history.push({
          pathname: "/AccordionComponent",
          state: {
            boxURL: props.portal.URL,
            userName: Name,
          },
        });
    }
  };


  console.log(url);
  return (
    <>
      {!reportsLoad && props.portal.SortOrder && (
        <div className={cardClass}>
          {logos[logos?.length - 1].includes("vault") ? (
            <>

              <div className="card h-100 vendor c-pointer">
                <div onClick={navigateToAccordian}>
                  <div className="portal-logo-container">
                    {logos[logos?.length - 1]?.split(".")[1] === "svg" ? (
                      <img className="portal-logo" src={require(`../images/vendor-logos/${logos[logos?.length - 1]}`).default} alt={`${props.portal?.Title} icon`} />
                    ) : (
                      <img className="portal-logo portal-logo-width" src={require(`../images/vendor-logos/${logos[logos?.length - 1]}`).default} alt={`${props.portal?.Title} icon`} />
                    )}
                  </div>
                  <div className="card-body w-100 border-top mt-4">
                    <div>
                      <ul className="pl-0">
                        {props.portal?.Description.map((row, index) => (
                          <li key={index} className={`text-secondary text-decoration-none ${index}`}>
                            {row}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {spinner &&
                  <div className="mx-auto d-block justify-content-center text-center mt-3">
                    <img src={spinnerImg} alt="spinner" />
                  </div>
                }
              </div>
            </>
          ) : (
            <a href={props.portal.URL} target="_blank" rel="noreferrer" className="decoration-none">
              <div className="card h-100 vendor">
                <div className="portal-logo-container">
                  {logos[logos?.length - 1].split(".")[1] === "svg" ? (
                    <img className="portal-logo" src={require(`../images/vendor-logos/${logos[logos?.length - 1]}`).default} alt={`${props.portal.Title} icon`} />
                  ) : (
                    <img className="portal-logo portal-logo-width" src={require(`../images/vendor-logos/${logos[logos?.length - 1]}`).default} alt={`${props.portal.Title} icon`} />
                  )}
                </div>
                <div className="card-body w-100 border-top mt-4">
                  <div>
                    <ul className="pl-0">
                      {props.portal?.Description.map((row, index) => (
                        <li key={index} className={`text-secondary text-decoration-none ${index}`}>
                          {row}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </a>
          )}
        </div>
      )}
      {!reportsLoad && props.portal?.quickLinks && <>
        <div className={cardClass}  >
          <div>
            <div className="card h-100 " style={{ padding: '0 25px' }}>
              <div className="portal-logo-container" style={{ marginTop: '20px', }}>
                <img className="portal-logo" src={require("../images/vendor-logos/Quicklinks.svg").default} alt="no logo" />
              </div>
              <div className="card-body w-100 border-top mt-4" style={{ height: '100%' }}>
                <Quicklink quickLinks={props?.portal?.quickLinks} />
              </div>
            </div>
          </div>
        </div>
      </>}
      {!reportsLoad && props?.portal?.news && <>
        <div className={cardClass}  >
          <div  >
            <div className="card h-100 " style={{ padding: '0 25px' }}>
              <div className="portal-logo-container" style={{ marginTop: '20px' }}>
                <img className="portal-logo" src={require("../images/vendor-logos/News.svg").default} alt="no logo" />
              </div>
              <div className="card-body w-100 border-top mt-4" >
                <News news={props?.portal?.news} />
              </div>
            </div>
          </div>
        </div>
      </>}
    </>
  );
};
export default Portal;
