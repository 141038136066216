import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";

const Header = props => {
    const { oktaAuth } = useOktaAuth();
    const history = useHistory();

    const onSignOut = async () => {
         sessionStorage.clear();
         oktaAuth.tokenManager.clear();
         localStorage.clear();
        await oktaAuth.signOut()
            .then(function () {
                localStorage.clear();
                sessionStorage.clear()
                history.push({ pathname: "/login" });
            })
            .catch(function (err) {

            })
    }

    return (
        <div className="header row">
            <div className="container">
                <div className="header row align-items-center justify-content-end">
                    <div className="col logo">
                        <img className="img-fluid" src={require(`../images/${props.brandPrefix}-logo.png`).default} alt="Well-th.View logo" />
                    </div>
                    <div className="col-auto">
                        <div>
                            {props.username &&
                                <span>Welcome, {props.username}</span>
                            }
                        </div>
                        <div className="signOut">
                            <button type="button" onClick={onSignOut} className="btn btn-link btn-sign-out sign-out">Sign Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;