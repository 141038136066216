import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

function PaginatedItems({ itemsPerPage, reports, setCurrentPosts, showFilter, paginate, currentPage }) {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentPosts(reports.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(reports.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, reports, setCurrentPosts]);

  useEffect(() => {
    handlePageClick({ selected: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter, reports]);

  const handlePageClick = (event) => {
    !showFilter && paginate(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % reports.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {reports.length > 0 && (
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={!!showFilter ? 0 : currentPage - 1}
        />
      )}
    </>
  );
}

export default PaginatedItems;
