import { CgCalendarNext } from "react-icons/cg";
import Carousel from 'react-bootstrap/Carousel';
import "./Quicklink.css"

const News = ({ news }) => {

    const chunkArray = (array, chunkSize) => {
        console.log("array", array)
        return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) => {
            const start = index * chunkSize;
            return array.slice(start, start + chunkSize);
        });
    };
    const linksGroups = news && chunkArray(news, 2);
    function truncateText(text, limit) {
        if (text.length <= limit) {
            return text;
        } else {
            return text.slice(0, limit) + '...';
        }
    }
    news && console.log("linksGroups", news.length)
    return (

        <Carousel data-bs-theme="black" className='m-Carousel' controls={false}>
            {linksGroups?.map((items, groupIndex) => (

                <Carousel.Item key={groupIndex}>

                    {items.map((item, itemIndex) => (
                        <div className="group-content" key={itemIndex}>
                            <>
                                {item.title && <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '-2px', color: '#6c757d!important' }}>{truncateText(item.title.rendered, 20)}</h3>}
                                {item.excerpt && <div className="div-x" dangerouslySetInnerHTML={{ __html: truncateText(item.excerpt.rendered, 30) }} />}
                                <a href={item.article} target="_blank" rel="noreferrer" >Go to Article <CgCalendarNext /></a>
                            </>
                            {itemIndex === 0 && items.length === 2 && (
                                <div style={{ color: "black", width: "100%", height: "1px", margin: "5px 0", backgroundColor: "#d8d6d6" }}></div>
                            )}
                        </div>
                    ))}
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

export default News