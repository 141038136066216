import { useState, lazy, Suspense } from "react";
import help from "../help/well-th-view-user-guide.pdf";
import "./footer.css";
import feedback from "../images/feedback.jpeg";
const Feed = lazy(() => import("./Feed"));
const Footer = (props) => {
  let initialFeedback = window.localStorage.getItem('feedBackSubmitted') ? true :false
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(initialFeedback)
  const appVersion = process.env.REACT_APP_VERSION;  
  const [showPopup, setShowPopup] = useState(false);

  window.onstorage = () => setTimeout(() => setFeedbackSubmitted(JSON.parse(window.localStorage.getItem('feedBackSubmitted'))), 1000);
  const showFeedback = () => {
    setShowPopup(!showPopup);
    !showPopup ? document.body.classList.remove("active-feedback") : document.body.classList.add("active-feedback") ;
    document.body.classList.remove("active-feedback")
  };

  return (
    <>
      {showPopup && !feedbackSubmitted &&  (
        <div style={{ border: "none", marginTop: "0px" }}>
          <div onClick={() => setShowPopup(false)} className="overlap"></div>
          <div className="iframe" style={{ marginTop: "0px", display: "flex", flexWrap: "wrap", maxWidth: "768px" }}>
            <Suspense
              fallback={
                <h1 className="iframe" style={{ textAlign: "center" }}>
                  Loading...
                </h1>
              }
            >
              <Feed />
            </Suspense>
          </div>
        </div>
      )}
      <div className="row justify-content-center footer footer-font-size  " >
        <div className="container fixed f-contain">
          <div className=" mt-2 grid-foot-c" style={{ justifyConten:'space-between'}}>
              <ul className="footer-menu" >
                <li >
                  <a href="https://hightoweradvisors.com/legal-and-privacy.html" target="_blank" rel="noreferrer">
                    Legal {"&"} Privacy
                  </a>
                </li>
                &nbsp;|&nbsp;
                <li >
                  <a href="legalTerms" target="_blank" rel="noreferrer">
                    Legal Terms Of Use
                  </a>
                </li>
                &nbsp;|&nbsp;
                <li >
                  <a className="color" href="https://hightoweradvisors.com/web-accessibility-policy.html" target="_blank" rel="noreferrer">
                    Web Accessibility
                  </a>
                </li>
                &nbsp;|&nbsp;
                <li>
                  <a href={help} target="_blank" rel="noreferrer">
                    Help
                  </a>
                </li>
              </ul>
            <div className="text-center footer-content" >
              <span>Securities offered through Hightower Securities, LLC,  MemberFINRA/SIPC,</span> &nbsp;
              <a style={{ padding: "0" }} href="https://hightoweradvisors.com/" target="_blank" rel="noreferrer">
                Hightower Advisors, LLC
              </a>
              &nbsp; is a SEC registered investment adviser, &nbsp;
              <a href="https://brokercheck.finra.org/" target="_blank" rel="noreferrer">
                brokercheck.finra.org
              </a>
             
            </div>
            <div className="text-center footer-content">
              Version {appVersion}. ©️{new Date().getFullYear()} Hightower Advisors. All Rights Reserved.
            </div>
          </div>
          {props.userInfo && <img onClick={showFeedback} className="feedback" src={feedback} alt="Feedback" />}
        </div>
      </div>
    </>
  );
};

export default Footer;