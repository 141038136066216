import React, { useState, useEffect } from "react";
import MenuService from "../services/menuService";
import Portal from "./portal";
import spinner from "../images/ajax-loader.gif";
import NewsServices from "../services/newsService";
// import menu from "../services/menuMockData";
// import PortalWide from "./portalWide";

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;



function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
}

const Portals = (props) => {
  const { userId, reportsComplete, reportsLoad, applications } = props;
  const [portals, setPortals] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [tileLoading, setTileLoading] = useState(true);
  const [colWidth, setColWidth] = useState(0);
  const [portalsInFirstRow, setPortalsInFirstRow] = useState([]);
  const [otherPortals, setOtherPortals] = useState([]);
  let width = useCurrentWidth();

 

  useEffect(() => {
    const menuItems = JSON.parse(localStorage.getItem('menuItems'));
    // console.log('menuItemsmenuItemsmenuItems', menuItems)
    if (menuItems && menuItems.length > 0) {
        //setPortals(result);
        props.renderComplete();
        setLoading(false);
        //setTileLoading(false)
        const usersData = JSON.parse(localStorage.getItem('Users'))
        if (usersData?.HidefromClientMenu === true) {
          for (let i = 0; i < menuItems.length; i++) {
            if (menuItems[i].Title === "Well-th Performance (Black Diamond)") {
              menuItems.splice(i, 1);
            }
          }

          //setPortalsInFirstRow(result);
        }
        //does our result contain 'Well-th Vault' application? If it does not, signal that we are not waiting on reports...
        const resultContainsVault = (Array.isArray(menuItems) && menuItems.length > 0 && menuItems.some(r => r.Title === 'Well-th Vault'));
        if (!resultContainsVault) {
          reportsComplete();
        }
        //end Well-th Vault check
        setPortals(menuItems);
        // console.log('MenuServiceCalling', menuItems)

    }
    else {
    MenuService.getData(props.userId, props.sessionId).then(
      
      (result) => {
       
        if (result) {
          
          //setPortals(result);
          props.renderComplete();
          setLoading(false);
          //setTileLoading(false)
          const usersData = JSON.parse(localStorage.getItem('Users'))
          if(usersData?.HidefromClientMenu === true){
          for (let i = 0; i < result.length; i++) {
            if (result[i].Title === "Well-th Performance (Black Diamond)") {
              result.splice(i, 1);
            }
          }
         
          //setPortalsInFirstRow(result);
        }
        //does our result contain 'Well-th Vault' application? If it does not, signal that we are not waiting on reports...
        const resultContainsVault = (Array.isArray(result) && result.length>0 && result.some(r => r.Title==='Well-th Vault'));
        if(!resultContainsVault) {
          reportsComplete();
        }
        //end Well-th Vault check
        const quickLinksData = result.filter((item) => item.SignOnMode === "BOOKMARK");
        const otherTiles = result.filter((item) => item.SignOnMode !== "BOOKMARK");
        const quickLinks = quickLinksData.length > 0 && quickLinksData;
          NewsServices.getNews(props?.userId).then((result) => {
            const news = result.length > 0 && result;
            const portalTiles = [...otherTiles, { quickLinks }, { news }]
            localStorage.setItem("menuItems", JSON.stringify(portalTiles)); 
            setPortals(portalTiles);
          }).catch((error) => {
            console.log('News error', error)
          }) 
        } else {
          props.setPageHeading("You don't have access.  Please contact your advisor practice.");
        }
      },
      (error) => {
        console.log(error);
      }
    );
    }
  }, [props, reportsComplete]);

  const handleResolution = (width) => {
    if (portals.length > 0) {
      if (width >= 738) {
        if (portals.length > 3) {
          setPortalsInFirstRow(portals.slice(0, 3));
          if (portals.length > 3) {
            setOtherPortals(portals.slice(3, portals.length));
          }
        } else {
          setPortalsInFirstRow(portals.slice(0, portals.length));
        }
      }
      if (440 <= width && width < 738) {
        if (portals.length > 2) {
          setPortalsInFirstRow(portals.slice(0, 2));
          setOtherPortals(portals.slice(2, portals.length));
        } else {
          setPortalsInFirstRow(portals.slice(0, portals.length));
        }
      }
      if (width < 440) {
        setPortalsInFirstRow(portals.slice(0, 1));
        setOtherPortals(portals.slice(1, portals.length));
      }
    }
  };

  useEffect(() => {
    handleResolution(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {

    if (portals) {
      portals.length === 2 ? setColWidth(6) : setColWidth(4);
      handleResolution(width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portals]);


  return (
    <div className="container fixed">
      {applications === 'loading' && <div className="mx-auto d-block justify-content-center text-center mt-5">
        <div>Loading your applications</div>
        <div><img src={spinner} alt="spinner" /></div>
      </div>
      }
      {!loading && colWidth > 0 && portals && portals.length > 0 &&
        <div>
          <div className="row">
            {portalsInFirstRow &&
              portalsInFirstRow.length > 0 &&
              portalsInFirstRow.map((row, index) => (
                <Portal reportsLoad={reportsLoad} userInfo={props.userInfo} userId={userId} key={index} portal={row} colWidth={colWidth} reportComplete={() => reportsComplete()} />
              ))}
          </div>
          {otherPortals.length > 0 &&
            <div id="otherPortalsRowContainer" className="row">
              {otherPortals && otherPortals.length > 0 && otherPortals.map((row, index) => <Portal userInfo={props.userInfo} userId={userId} key={index} portal={row} colWidth={colWidth} />)}
            </div>
          }
          <div className="row">
          </div>
        </div>}

    </div>
  );
};

export default Portals;