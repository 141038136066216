import React, { useState } from "react";
import uuid from "react-uuid";
import { Accordion, Form, Table, Spinner } from "react-bootstrap";
import { MdOutlineFolderOpen } from "react-icons/md";
import { BsFileEarmarkFill, BsFillEyeFill } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import pershingPdfReports from "../../../services/pershingPdfReports";
import ReactPaginate from "react-paginate";
import PdfPreview from "./pdfPreview";
const TaxComponent = ({ data, formatedDate }) => {
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const [pdfFileName, setPdfFileName] = useState("");
  const [showPDF, setShowPDF] = useState(false);
  const [selectedYear, setSelectedYear] = useState("all");
  const [selectedQuarter, setSelectedQuarter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  let tax = data?.documents;
  const uniqueYears = [...new Set(data?.documents.map((doc) => doc.REVISION_DATE.substring(6)))];

  if (selectedYear !== "all") {
    const filteredYear = tax.filter((doc) => doc.REVISION_DATE.includes(selectedYear));
    tax = filteredYear;
  }

  if (selectedQuarter !== "all") {
    const quarterMonths = {
      q1: ["01", "02", "03"],
      q2: ["04", "05", "06"],
      q3: ["07", "08", "09"],
      q4: ["10", "11", "12"],
    };
    const qaurterly = tax.filter((doc) => {
      const docMonth = doc.REVISION_DATE.substring(0, 2);
      const docQuarter = Math.ceil(docMonth / 3);
      const selectedYearNum = parseInt(selectedYear);
      return doc.REVISION_DATE.substring(6) === selectedYearNum.toString() && docQuarter.toString() === selectedQuarter.substring(1) && quarterMonths[selectedQuarter]?.includes(docMonth);
    });
    tax = qaurterly;
  }
  if (selectedYear === "all") {
    tax = data && data?.documents;
  }

  const taxPageCount = Math.ceil(tax?.length / itemsPerPage);
  const handlePageTax = (selectedPage) => setCurrentPage(selectedPage.selected + 1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  //b.REVISION_DATE  a.REVISION_DATE
  const taxForCurrentPage = tax
  ?.sort((a, b) => {
    const yearDiff = parseInt(b.REVISION_DATE.substring(6), 10) - parseInt(a.REVISION_DATE.substring(6), 10);
    if (yearDiff !== 0) {
      // Sort by year in descending order
      return yearDiff;
    } else {
      // If year is the same, sort by month in descending order
      return parseInt(b.REVISION_DATE.substring(0, 2), 10) - parseInt(a.REVISION_DATE.substring(0, 2), 10);
    }
  }).slice(startIndex, endIndex);

  const showDownloadPDF = async (Id, name, i, accounttype) => {
    setDownloadLoading(true);
    setLoadingIndex(i);

    const response = await pershingPdfReports.getReports(Id, accounttype);
    response.getPDF
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        setDownloadLoading(false);
        link.setAttribute("download", `${name}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        setDownloadLoading(false);
      });
  };

  const showPreviewPDF = async (Id, name, i, accounttype) => {
    setPreviewLoading(true);
    setPdfFileName(name);
    setLoadingIndex(i);
    const response = await pershingPdfReports.getReports(Id, accounttype);
    response.getPDF
      .then((response) => {
        setPreviewLoading(false);
        setPdfUrl(response);
        setShowPDF(true);
      })
      .catch((err) => {
        console.log(err);
        setPreviewLoading(false);
      });
  };
  return (
    <>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <span className="accordion-heading">
            <MdOutlineFolderOpen className="icon" />
            Tax Forms ( {tax?.length < data?.documents?.length ? (tax?.length + " / " + data?.documents?.length) : data?.documents?.length} )
          </span>
        </Accordion.Header>
        <Accordion.Body>
          <div>
            <div className="row">
              <div className="col-md-8"></div>
              <div className="col-md-4">
                <div className="filter-container">
                  <div className="mr-3 d-flex">
                    Year
                    <Form.Select aria-label="Default select" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                      <option value="all">All</option>
                      {uniqueYears &&
                        uniqueYears
                          .sort((a, b) => b - a)
                          .map((year) => (
                            <option key={uuid()} value={year}>
                              {year}
                            </option>
                          ))}
                    </Form.Select>
                  </div>
                  <div className="d-flex">
                    Quarter
                    <Form.Select aria-label="Default select" value={selectedQuarter} onChange={(e) => setSelectedQuarter(e.target.value)}>
                      <option value="all">All</option>
                      {selectedYear !== "all" && (
                        <>
                          <option value="q1">Q1</option>
                          <option value="q2">Q2</option>
                          <option value="q3">Q3</option>
                          <option value="q4">Q4</option>
                        </>
                      )}
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="table">
              <Table striped>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>ACCOUNT</th>
                    <th>STATEMENT DATE</th>
                    <th>PAGES</th>
                  </tr>
                </thead>
                <tbody>
                  {taxForCurrentPage &&
                    taxForCurrentPage.map((doc, i) => {
                      const docUuid = uuid();
                      return (
                        <tr key={docUuid}>
                          <td>
                            <BsFileEarmarkFill className="grid-icons mr-1" />
                            {doc.SHORT_NAME}
                          </td>
                          <td>{doc.ACCT}</td>
                          <td>{formatedDate(doc.REVISION_DATE)}</td>
                          <td>{doc.PAGECNT}</td>
                          <td>
                            {!previewLoading || loadingIndex !== i ? (
                              <BsFillEyeFill className="grid-icons pdfViwer" onClick={() => showPreviewPDF(doc.DOC_ID, doc.SHORT_NAME, i, doc.ACCOUNT_TYPE)} />
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </td>
                          <td>
                            {!downloadLoading || loadingIndex !== i ? (
                              <HiDownload className="grid-icons pdfViwer" onClick={() => showDownloadPDF(doc.DOC_ID, doc.SHORT_NAME, i, doc.ACCOUNT_TYPE)} />
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>{" "}
              {taxPageCount > 1 && (
                <>
                  <div className="pagination">
                    <ReactPaginate
                      nextLabel="►"
                      previousLabel="◄"
                      onPageChange={handlePageTax}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={taxPageCount}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName={currentPage === 1 ? "page-item disabled" : "page-item"}
                      previousLinkClassName="page-link"
                      nextClassName={currentPage === taxPageCount ? "page-item disabled" : "page-item"}
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      disableNextButton={currentPage === taxPageCount}
                      disablePrevButton={currentPage === 1}
                    />
                  </div>
                </>
              )}
              {tax && tax.length === 0 && <p className="no-q">No Document For This Quarter</p>}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {showPDF && <PdfPreview setShowPDF={setShowPDF} pdfUrl={pdfUrl} pdfFileName={pdfFileName} />}
    </>
  );
};
export default TaxComponent;