
import axios from "axios";
import SendMessageToRoom from "./postWebexMessageService";
const Email = (JSON.parse(localStorage.getItem("okta-token-storage")))?.accessToken?.claims?.sub;

axios.interceptors.response.use(null, (error) => {
  if (!error.response) {
    const messagePayload = {
      message: `
      NETWORK ERROR

      Domain Name: ${window.location.host}} 
      Client Email: ${Email}
      Mulesoft Endpoint: ${error.config.url} 
      Status Code: ${error.response ? error.response.status : 'Unknown'} 
      Error Message: ${error.message}.`
    };
    SendMessageToRoom(messagePayload);

    window.location.href = window.location.origin + "/error";

  } else if (error.response && error.response.status >= 400 && error.response.status <= 599 && error.response.status !== 404) {
    const messagePayload = {
      message: `
      UNABLE TO RETRIEVE DATA

      Domain Name: ${window.location.host} 
      Client Email: ${Email}
      Mulesoft Endpoint: ${error.config.url} 
      Status Code: ${error.response ? error.response.status : 'Unknown'} 
      Error Message: ${error.message}.`
    };
    SendMessageToRoom(messagePayload);
    window.location.href = window.location.origin + "/error";
  } else if (!(error.response && error.response.status >= 400 && error.response.status <= 599 && error.response.status !== 404)) {
    const messagePayload = {
      message: `
      UNABLE TO RETRIEVE DATA

      Domain Name: ${window.location.host} 
      Client Email: ${Email}
      Mulesoft Endpoint: ${error.config.url} 
      Status Code: ${error.response ? error.response.status : 'Unknown'} 
      Error Message: ${error.message}.`
    };
    if (SendMessageToRoom(messagePayload)) {
      console.log("Message sent to Webex Teams", messagePayload);
      window.location.href = window.location.origin + "/error";
    }

    console.error(error);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  config.timeout = 60000;
  return config;
});

const service = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

export default service;